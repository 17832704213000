.mobile-redirect-main {
	height: 100vh;
	background-color: #161616;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	flex-direction: column;
}

.mobile-redirect-main h1 {
	font-size: 20px;
	font-family: 'Cairo Normal';
	letter-spacing: 2px;
	padding: 1em;
}

.mobile-redirect-inner {
	width: 100%;
	height: 150px;
}
