@font-face {
  font-family: "Cairo Bold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Bold.ttf");
  font-weight: 700;
}
.game-modal {
  &-inner {
    width: 90% !important;
    .ant-modal-content {
      border-radius: 0;
      border: 1px solid #fff017;
      background: rgba(0, 0, 0, 0.2);
      box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(11.6334px);
    }
    .ant-modal-header {
      background-color: transparent;
      .ant-modal-title {
        color: #fff;
        font-size: 40px;
        text-align: center;
        font-family: "Cairo Bold";
        margin-bottom: 2.5rem;
        margin-top: 1.5rem;
      }
    }
  }
  &-details {
    color: #fff;
    line-height: 20px;
    font-size: 16px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .game-modal {
    .ant-modal-title {
      font-size: 24px !important;
      margin-bottom: 0.5rem !important;
      margin-top: 1rem !important;
      line-height: 28px;
    }
    &-inner {
      .ant-modal-content {
        border-radius: 0;
        border: 1px solid #fff017;
        background: rgba(0, 0, 0, 0.2);
        box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(11.6334px);
        padding: 20px 8px;
      }
    }
  }
  .game-form-modal {
    &-inner {
      width: 100% !important;
      max-width: 94%;
    }
  }
  .contactus-form {
    width: 100%;
    &-button {
      width: 72%;
    }
  }
}
