.contact-details {
  margin-top: 0.5rem;
  &-text {
    color: white;
    font-size: 24px;
    margin-bottom: 0.6rem;
  }
  &-edit-icon {
    position: absolute;
    top: 41px;
    left: 95%;
  }
  &-edit-icon1 {
    position: absolute;
    top: 47px;
    left: 95%;
  }
  &-input-field {
    background-color: #1b1b1b;
    border: none;
    height: 48px;
    padding: 0 1rem;
    font-size: 14px;
    color: #fff;
    padding-right: 2.5rem;
    &:focus {
      border: 1px solid #fff017;
      outline: none;
    }
  }
  .fields-contact {
    display: grid;
    align-content: baseline;
    height: 96px;
  }
  &-password-icon {
    position: absolute;
    top: 46px;
    left: 95.5%;
    svg {
      color: #fff017;
      opacity: 0.6;
    }
  }
}
.password-matched {
  color: rgb(66, 197, 66);
  font-size: 12px;
  font-family: "Cairo Medium";
}
.error {
  border: 1px solid red;
}
@media (min-width: 320px) and (max-width: 480px) {
  .contact-details {
    margin-top: 1rem;
    &-text {
      font-size: 24px;
      margin-bottom: 1rem;
    }
    &-edit-icon {
      top: 43px;
      left: 90%;
    }
    &-edit-icon1 {
      top: 48px;
      left: 90%;
    }
    &-input-field {
      padding-right: 2.5rem;
    }
    &-password-icon {
      left: 91%;
    }
  }
}
