@font-face {
  font-family: "Cairo Bold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Bold.ttf");
  font-weight: 700;
}
.private-game {
  &-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    &-copy {
      display: flex;
      border-bottom: 1px solid #b3b3b3;
      padding-bottom: 0.4rem;
      align-items: center;
      font-size: 14px;
      margin-top: 0.5rem;
      &-div {
        width: 84%;
      }
    }
    &-button {
      font-size: 15px;
      width: 69px;
      padding: 0;
      margin-left: 1rem;
      &:hover {
        color: #f9f59a !important;
        border-color: #f9f59a !important;
      }
    }
    &-share {
      margin-right: 0.8rem;
      font-size: 16px;
      line-height: 22px;
    }
  }
  &-form-modal {
    &-inner {
      width: 58% !important;
      .ant-modal-content {
        border-radius: 0;
        border: 1px solid #fff017;
        background: rgba(0, 0, 0, 0.2);
        box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(11.6334px);
      }
      .ant-modal-body {
        color: #fff;
        text-align: center;
        font-size: 20px;
      }
      .ant-modal-header {
        background-color: transparent;
        .ant-modal-title {
          color: #fff;
          font-size: 40px;
          text-align: center;
          font-family: "Cairo Bold";
          margin-bottom: 2.5rem;
          margin-top: 1.5rem;
        }
      }
    }
  }
  .contactus-form {
    width: 85%;
    margin: auto;
    &-fieldscont {
      display: grid;
      align-content: baseline;
      height: 110px;
    }
    &-textarea {
      display: grid;
      align-content: baseline;
      height: 270px;
      &-field {
        background-color: #1b1b1b;
        border: none;
        height: 238px;
        padding: 1.8rem 1rem;
        font-size: 16px;
        font-family: "Cairo Normal";
        color: #fff;
      }
    }
    &-field {
      background-color: #1b1b1b;
      border: none;
      height: 56px;
      padding: 0 1rem;
      font-size: 16px;
      font-family: "Cairo Normal";
      color: #fff;
    }
    &-button {
      transform: skew(-20deg);
      width: 35%;
      height: 56px;
      border: none;
      color: #000;
      font-family: "Cairo Bold";
      font-size: 18px;
      margin-top: 1.5rem;
      margin-bottom: 2rem;
      cursor: pointer;
      &-text {
        transform: skew(20deg);
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .contactus-form-modal {
    &-inner {
      width: 100% !important;
      max-width: 94%;
    }
  }
  .contactus-form {
    width: 100%;
    &-button {
      width: 72%;
    }
  }
  .private-game {
    &-form-modal {
      &-inner {
        width: 100% !important;
        .ant-modal-header {
          .ant-modal-title {
            font-size: 18px;
            margin-bottom: 0.5rem;
            margin-top: 1rem;
          }
        }
      }
    }
    &-link {
      display: block;
      margin-top: 1rem;
      &-button {
        width: 100%;
        margin-left: 0rem;
      }
      &-copy {
        display: block;
        border: none;
        &-div {
          width: 100%;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
