.game-steps {
  display: flex;
  justify-content: space-around;
  width: 32%;
  padding: 1rem 0;
  border: 1px solid #fff017;
  border-radius: 10px;
  &-text {
    padding: 1rem;
    display: grid;
    // align-content: space-between;
    &-step {
      font-size: 28px;
      color: #000;
      background-color: #fff017;
      position: absolute;
      margin-top: -3rem;
      width: 54px;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: skew(-20deg);
      margin-left: 1rem;
    }
    &-join {
      font-size: 20px;
      line-height: 24px;
      padding-bottom: 2rem;
      padding-top: 1rem;
      color: #fff;
    }
  }
  &-images {
    position: relative;
    width: 52%;
    &-left {
      position: absolute;
      top: 62%;
      left: -25%;
    }
    &-right {
      position: absolute;
      top: 73%;
      left: 95%;
    }
  }
  &-mobile-arrow {
    display: none;
    position: absolute;
    top: 360px;
    left: 76%;
  }
  .gamestepimage3 {
    height: 280px;
    width: 62%;
  }
  &-image {
    width: 100%;
    height: 240px;
  }
}
.slick-dots-bottom {
  bottom: 0px !important;
}
.ant-carousel .slick-list {
  width: 90% !important;
  margin: auto !important;
}
@media (min-width: 320px) and (max-width: 480px) {
  .game-steps {
    display: grid;
    padding: 1.3rem 0;
    width: 96%;
    margin: 2rem auto;
    height: 430px;
    &-text {
      width: 100%;
      padding-left: 1rem;
      padding-top: 2rem;
      &-step {
        font-size: 22px;
        margin-top: -4rem;
      }
      &-join {
        font-size: 20px;
        line-height: 24px;
        width: 100%;
        margin-bottom: 0.3rem;
      }
    }
    &-images {
      width: 100%;
      &-left {
        display: none;
      }
      &-right {
        display: none;
      }
    }
    &-mobile-arrow {
      display: block;
    }
    .gamestepimage3 {
      width: 100%;
    }
  }
  .line-height37 {
    line-height: 22px !important;
  }
}
@media (min-width: 1024px) and (max-width: 1300px) {
  .game-steps {
    &-text {
      // width: 46%;
      // padding-left: 5rem;
      // padding-right: 2rem;
    }
  }
}
