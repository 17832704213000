.counttimer {
  &-inprogress {
    font-size: 14px;
  }
  &-remaining {
    display: grid;
    text-align: center;
    &-text {
      font-size: 12px;
      line-height: 12px;
    }
  }
  &-deadline-content {
    display: flex;
    justify-content: center;
    &-cont {
      margin-right: 6px;
      text-align: center;
      &-timer {
        border: 0.2px solid #fff017;
        height: 20px;
        width: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-family: "Cairo Medium";
        border-radius: 4px;
      }
      &-label {
        color: #adadad;
        font-size: 8px;
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .counttimer {
    &-inprogress {
      text-align: center;
    }
    &-remaining {
      &-text {
        font-size: 12px;
      }
    }
  }
}
