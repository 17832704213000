@font-face {
  font-family: "Cairo Normal";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Cairo Bold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Bold.ttf");
  font-weight: 700;
}
.internal-main {
  margin: auto;
  padding: 0 2rem;
  background-color: #000;
  &-notifications {
    position: relative;
    &-count {
      position: absolute;
      background-color: #fff017;
      color: #000;
      border-radius: 222px;
      font-size: 12px;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0px;
      right: 0;
    }
  }
}
.internal-navigation-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5rem;
}
.internalnav-main {
  width: 36%;
}
.logo img {
  height: 74px;
}
.internal-navbar-right {
  display: flex;
  justify-content: space-between;
  width: 25%;
}
.nav-links {
  display: flex;
  list-style-type: none;
  text-decoration: none;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  &-below-line {
    height: 4px;
    margin-top: 0.5rem;
    margin-left: -10px;
    width: 30px;
    background-color: #fff017;
  }
}
.navbar-signup-button {
  margin-left: 10px;
  color: #fff;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  height: 36px;
  width: 110px;
  transform: skew(-20deg);
  margin-right: 8px;
  font-family: "Cairo Bold";
  font-size: 14px;
  &:hover {
    color: #fff017;
    border-color: #fff017;
  }
}
.navbar-login-button {
  border: none;
  height: 56px;
  width: 165px;
  cursor: pointer;
  transform: skew(-20deg);
  font-family: "Cairo Bold";
  font-size: 18px;
}
.button-text {
  transform: skew(20deg);
}
.nav-links-list {
  color: #fff;
  text-decoration: none;
  font-family: "Cairo Normal";
  font-size: 16px;
}
.nav-links-list:hover {
  font-family: "Cairo Bold";
  color: #fff017;
}
.profile-details {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 130px;
}
.username {
  color: #fff;
  font-size: 16px;
  font-family: "Cairo Bold";
  display: flex;
  flex-direction: column;
  text-align: center;
}
.profile-image {
  background-color: #fff017;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  &-icon {
    path {
      fill: #000;
    }
  }
}
.internal-profile-icon {
  margin-left: 8px;
  cursor: pointer;
}
.internal-nav-dropdown {
  top: 100px;
  .ant-dropdown-menu {
    padding: 1px;
    border-radius: 0;
    border: 1px solid #fff017;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(11.6334px);
    .ant-dropdown-menu-item {
      color: #fff;
      border-bottom: 1px solid #d4d4d4;
      padding: 16px 12px;
      margin: 0 8px;
      border-radius: 0;
      font-size: 16px;
      font-family: "Cairo Normal";
    }
  }
}
.mobile-navbar-right {
  display: none;
}
.internal-mobile-drawer {
  background: rgba(0, 0, 0, 0.2) !important;
  box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(11.6334px);
  width: 92% !important;
  margin: 70px auto auto auto;
  .ant-drawer-body {
    padding: 0px 24px;
  }
  .ant-drawer-header {
    display: grid;
  }
  .ant-drawer-header-title {
    display: none;
  }
}
.dasboard-notification {
  background-color: #fff017;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  height: 20px;
  width: 20px;
  border-radius: 222px;
  position: absolute;
  left: 72px;
  top: -12px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .internalnav-main {
    display: none;
  }
  .internal-profile-icon {
    margin-right: 8px;
  }
  .internal-navbar-right {
    display: none;
  }
  .logo img {
    height: 58px;
  }
  .internal-navigation-bar {
    padding: 10px 1rem;
  }
  .external-hamburg {
    display: block;
  }
  .mobile-nav-links {
    display: grid;
    list-style-type: none;
    padding: 0;
  }
  .mobile-links-list {
    font-family: "Cairo Normal";
    font-size: 21px;
    margin: 0rem 2rem;
    text-decoration: none;
    text-align: center;
    padding: 2.2rem 0;
    border-bottom: 0.71px solid rgba(255, 255, 255, 0.4);
  }
  .mobile-login-button {
    border: none;
    height: 36px;
    width: 80px;
    cursor: pointer;
    transform: skew(-20deg);
    font-family: "Cairo Bold";
    font-size: 14px;
    position: relative;
    color: #000;
  }
  .mobile-navbar-right {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
  }
  .profile-details {
    flex-direction: row-reverse;
  }
  .navbar-signup-button {
    border: none;
    width: 90px;
    color: #fff017;
    padding: 2px;
  }
  .button-text {
    display: flex;
    justify-content: space-between;
  }
  .profile-image {
    height: 42px;
    width: 42px;
  }
  .dasboard-notification {
    left: 77%;
    top: 31px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .internalnav-main {
    display: none;
  }
  .internal-profile-icon {
    margin-right: 8px;
  }
  .internal-navbar-right {
    display: none;
  }
  .logo img {
    height: 58px;
  }
  .internal-navigation-bar {
    padding: 10px 1rem;
  }
  .external-hamburg {
    display: block;
  }
  .mobile-nav-links {
    display: grid;
  }
  .mobile-links-list {
    font-family: "Cairo Normal";
    font-size: 18px;
    text-decoration: none;
    margin: 1rem 0;
  }
  .mobile-login-button {
    border: none;
    height: 36px;
    width: 80px;
    cursor: pointer;
    transform: skew(-20deg);
    font-family: "Cairo Bold";
    font-size: 14px;
    position: relative;
    color: #000;
  }
  .mobile-navbar-right {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
@media (min-width: 767px) and (max-width: 992px) {
  .internalnav-main {
    display: none;
  }
  .internal-navbar-right {
    display: none;
  }
  .logo img {
    height: 58px;
  }
  .internal-navigation-bar {
    padding: 10px 1rem;
  }
  .external-hamburg {
    display: block;
  }
  .mobile-nav-links {
    display: grid;
  }
  .mobile-links-list {
    font-family: "Cairo Normal";
    font-size: 18px;
    margin: 1rem 0;
    text-decoration: none;
  }
  .mobile-login-button {
    border: none;
    height: 36px;
    width: 80px;
    cursor: pointer;
    transform: skew(-20deg);
    font-family: "Cairo Bold";
    font-size: 14px;
    position: relative;
    color: #000;
  }
  .mobile-navbar-right {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
