.video-cont {
  width: 670px;
  height: 450px;
}
.video-toggle-btn {
  position: absolute;
  background: transparent;
  border: none;
  z-index: 111;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-ant-icon .anticon svg {
  height: 70px;
  width: 70px;
  color: #fff017;
}
@media (min-width: 320px) and (max-width: 480px) {
  .video-ant-icon .anticon svg {
    height: 40px;
    width: 40px;
  }
  .video-cont {
    height: 190px;
    transform: skew(-3deg);
    width: 300px;
    margin: 0;
  }
  .video-toggle-btn {
    display: none;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .video-ant-icon .anticon svg {
    height: 40px;
    width: 40px;
  }
}
@media (min-width: 1024px) and (max-width: 1240px) {
  .video-ant-icon .anticon svg {
    width: 60px;
    height: 60px;
  }
}