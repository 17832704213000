.dashboard {
  padding: 2rem 7rem;
  max-width: 1240px;
  margin: auto;
  &-title {
    font-size: 40px;
    text-align: center;
    letter-spacing: 0.5px;
    line-height: 44px;
  }
  &-ongoing {
    margin-top: 1rem;
    &-title {
      font-size: 32px;
      letter-spacing: 0.5px;
      line-height: 36px;
      margin-bottom: 1.5rem;
    }
    &-cont {
      padding: 2rem 1.5rem;
      border: 1px solid transparent;
      border-image: linear-gradient(
        180deg,
        #fff017 0%,
        rgba(255, 240, 23, 0) 140.54%
      );
      border-image-slice: 1;
    }
    &-nodata {
      color: #fff;
      font-size: 20px;
      text-align: center;
    }
  }
  &-past {
    margin-top: 2rem;
    &-title {
      font-size: 32px;
      letter-spacing: 0.5px;
      line-height: 40px;
      margin-bottom: 1rem;
      margin-top: 1.5rem;
    }
    &-cont {
      padding: 2rem 2rem;
      border: 1px solid transparent;
      border-image: linear-gradient(
        180deg,
        #fff017 0%,
        rgba(255, 240, 23, 0) 140.54%
      );
      border-image-slice: 1;
    }
  }
  &-button {
    text-align: center;
    margin-top: 2rem;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .dashboard {
    padding: 2rem 1rem;
    &-title {
      font-size: 30px;
      text-align: left;
      line-height: 33px;
    }
    &-ongoing {
      &-title {
        font-size: 24px;
        line-height: 27px;
        margin-bottom: 1rem;
      }
    }
    &-past {
      margin-top: 2rem;
      &-title {
        font-size: 24px;
        line-height: 27px;
        margin-bottom: 2rem;
      }
    }
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .dashboard {
    padding: 2rem 1rem;
    &-title {
      font-size: 30px;
      text-align: left;
      line-height: 33px;
    }
    &-ongoing {
      &-title {
        font-size: 24px;
        line-height: 27px;
        margin-bottom: 1rem;
      }
    }
    &-past {
      margin-top: 2rem;
      &-title {
        font-size: 24px;
        line-height: 27px;
        margin-bottom: 2rem;
      }
    }
  }
}
