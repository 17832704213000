@font-face {
  font-family: "Cairo Normal";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Cairo Bold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Bold.ttf");
  font-weight: 700;
}
.navigation-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5rem;
  background-color: #000;
}
.externalnav-main {
  width: 34%;
}
.externallogo img {
  height: 74px;
  width: 154px;
}
.right-side {
  display: flex;
}
.nav-links {
  display: flex;
  list-style-type: none;
  text-decoration: none;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}
.externalnav-signup-button {
  margin-left: 10px;
  padding: 5px 10px;
  color: #fff;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  height: 44px;
  width: 125px;
  transform: skew(-20deg);
  margin-right: 8px;
  font-family: "Cairo Bold";
  font-size: 16px;
  z-index: 111;
  &:hover {
    color: #d9d9d9;
  }
}
.externalnav-login-button {
  font-size: 16px;
  background-color: #fff017;
  &:hover {
    color: #1b1b2b;
    background-color: #ffff17;
  }
}
.exbutton-text {
  transform: skew(20deg);
  text-align: center;
}
.externalnav-links-list {
  font-family: "Cairo Normal";
  font-size: 18px;
}
.nav-links-list:hover {
  font-family: "Cairo Bold";
}
.externalnav-links-list-item {
  color: #fff;
  text-decoration: none;
}
.externalnav-links-list-item:hover {
  font-family: "Cairo Bold";
  color: #fff017;
}
.externalnav-login-button-spark {
  position: absolute;
}
.external-hamburg {
  display: none;
}
.external-mobile-drawer {
  background: rgba(0, 0, 0, 0.2) !important;
  box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(11.6334px);
  height: 530px !important;
  .ant-drawer-body {
    padding: 12px 24px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .externalnav-main {
    display: none;
  }
  .right-side {
    display: none;
  }
  .externallogo img {
    height: 58px;
  }
  .navigation-bar {
    padding: 10px 1rem;
  }
  .external-hamburg {
    display: block;
  }
  .mobile-nav-links {
    display: grid;
  }
  .mobile-links-list {
    font-family: "Cairo Normal";
    font-size: 18px;
    margin: 1rem 0;
  }
  .mobile-signup-button {
    margin-left: 10px;
    padding: 5px 10px;
    color: #fff;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    height: 40px;
    width: 110px;
    transform: skew(-20deg);
    margin-right: 8px;
    font-family: "Cairo Bold";
    font-size: 16px;
  }
  .mobile-login-button {
    border: none;
    height: 36px;
    width: 80px;
    cursor: pointer;
    transform: skew(-20deg);
    font-family: "Cairo Bold";
    font-size: 14px;
    position: relative;
    color: #000;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .externalnav-main {
    display: none;
  }
  .right-side {
    display: none;
  }
  .externallogo img {
    height: 58px;
  }
  .navigation-bar {
    padding: 10px 1rem;
  }
  .external-hamburg {
    display: block;
  }
  .mobile-nav-links {
    display: grid;
  }
  .mobile-links-list {
    font-family: "Cairo Normal";
    font-size: 18px;
    margin: 1rem 0;
  }
  .mobile-signup-button {
    margin-left: 10px;
    padding: 5px 10px;
    color: #fff;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    height: 36px;
    width: 80px;
    transform: skew(-20deg);
    margin-right: 8px;
    font-family: "Cairo Bold";
    font-size: 14px;
  }
  .mobile-login-button {
    border: none;
    height: 36px;
    width: 80px;
    cursor: pointer;
    transform: skew(-20deg);
    font-family: "Cairo Bold";
    font-size: 14px;
    position: relative;
    color: #000;
  }
}
@media (min-width: 767px) and (max-width: 992px) {
  .externalnav-main {
    display: none;
  }
  .right-side {
    display: none;
  }
  .externallogo img {
    height: 58px;
  }
  .navigation-bar {
    padding: 10px 1rem;
  }
  .external-hamburg {
    display: block;
  }
  .mobile-nav-links {
    display: grid;
  }
  .mobile-links-list {
    font-family: "Cairo Normal";
    font-size: 18px;
    margin: 1rem 0;
  }
  .mobile-signup-button {
    margin-left: 10px;
    padding: 5px 10px;
    color: #fff;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    height: 36px;
    width: 80px;
    transform: skew(-20deg);
    margin-right: 8px;
    font-family: "Cairo Bold";
    font-size: 14px;
  }
  .mobile-login-button {
    border: none;
    height: 36px;
    width: 80px;
    cursor: pointer;
    transform: skew(-20deg);
    font-family: "Cairo Bold";
    font-size: 14px;
    position: relative;
    color: #000;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .externalnav-signup-button {
    height: 40px;
    width: 120px;
    font-size: 16px;
  }
  .externalnav-login-button {
    height: 40px;
    width: 120px;
    font-size: 16px;
  }
  .externalnav-main {
    width: 45%;
  }
  .externalnav-links-list {
    font-size: 14px;
  }
  .externallogo img {
    height: 85px;
  }
}
