.game-rules {
  display: flex;
  padding: 0 5rem;
  justify-content: center;
  &-rtl {
    flex-direction: row-reverse;
    justify-content: end;
  }
  &-margin {
    margin-top: 0.5rem;
  }
  &-cont {
    &-number {
      width: 77px;
      height: 85px;
      transform: skew(-10deg);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      position: absolute;
      top: 34px;
      left: -66px;
      background-color: #000;
      z-index: 111;
    }
    &-details {
      transform: skew(-10deg);
      padding: 0 2rem;
      width: 337px;
      font-size: 13px;
      text-align: left;
      height: 150px;
      display: flex;
      align-items: center;
      line-height: 18px;
      &-text {
        transform: skew(10deg);
      }
    }
  }
  &-images {
    display: flex;
    align-items: end;
    &-icon {
      height: 100px;
      width: 320px;
    }
  }
  &-mobilearrow {
    display: none;
  }
  &-collapse {
    width: 100%;
    color: #fff;
    border: none;
    .ant-collapse-item {
      border: none;
      transform: skew(10deg);
    }
    .ant-collapse-item > .ant-collapse-header {
      color: #fff;
      font-family: "Cairo Bold";
      font-size: 16px;
      line-height: 18px;
      padding: 8px 8px;
    }
    .ant-collapse-content {
      background-color: transparent;
      color: #fff;
      border-top: none;
      overflow-y: scroll;
      height: 80px;
      .ant-collapse-content-box {
        padding: 4px 12px;
        height: 100%;
      }
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #fff017;
        width: 12px;
        &:hover {
          background-color: #fff017;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .game-rules {
    padding: 0 1rem;
    justify-content: center;
    margin-bottom: 2rem;
    &-rtl {
      flex-direction: row;
    }
    &-cont {
      &-details {
        height: 200px;
        width: 290px;
        transform: skew(-4deg);
        &-text {
          transform: skew(4deg);
        }
      }
      &-number {
        width: 73px;
        height: 63px;
        font-size: 24px;
        left: -55px;
        top: 50px;
      }
    }
    &-mobilearrow {
      position: absolute;
      top: 70px;
      left: -45px;
      display: block;
    }
    &-collapse {
      .ant-collapse-item {
        transform: skew(4deg);
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 400px) {
  .game-rules {
    padding: 0 1rem;
    justify-content: center;
    margin-bottom: 2rem;
    &-rtl {
      flex-direction: row;
    }
    &-cont {
      &-details {
        height: 210px;
        width: 275px;
        padding-left: 3rem;
        padding-right: 0.5rem;
      }
      &-number {
        width: 55px;
        height: 55px;
        font-size: 24px;
        left: -25px;
        top: 70px;
      }
    }
    &-mobilearrow {
      top: 75px;
      left: -37px;
    }
  }
}
