.game {
  max-width: 1240px;
  margin: auto;
  padding: 0 7rem;

  &-button {
    padding: 3rem 0 4rem 0;

    &-class {
      width: 362px;
    }
  }

  &-heading {
    font-size: 40px;
    margin-bottom: 1.8rem;
    line-height: 44px;
  }

  &-join-action {
    font-size: 16px;
    letter-spacing: 0.31em;
    padding: 2rem 0 0.3rem 0;
    line-height: 18px;
  }
}

// Update the default color of Ant Design tabs to white
.ant-tabs {
  color: white !important; // Change the default color to white
}

.ant-select-selection-item {
  font-family: "Cairo Normal";
}

.game-info {
  max-width: 980px;
  margin: auto;
  background-color: transparent !important;

  &-title {
    font-size: 40px;
    line-height: 44px;
    text-align: center;
  }

  &-cont {
    padding: 2rem 2rem;
    height: 750px;
    border-image-slice: 1;
    margin: 1.5rem 4rem;
    color: grey;

    &-tab {
      .ant-tabs-ink-bar {
        background-color: transparent !important;
      }

      .ant-tabs-content-holder {
        background-color: transparent !important;
      }

      .ant-tabs-tab {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 24px; // Default font size
        color: grey; // Default text color
      //  transition: color 0.3s ease; // Smooth transition for text color
      }

      .ant-tabs-tab:hover {
        color: grey; // Keep text grey on hover
      }

      .ant-tabs-tab + .ant-tabs-tab {
        margin: 0;
        color: grey;
      }

      .ant-tabs-tab.ant-tabs-tab-active {
        border-bottom: 1px solid yellow;
        outline: none;
        color: white; // Active tab text color
        //transition: color 0.3s ease;

        .ant-tabs-tab-btn {
          color: white;
          font-size: 24px;
          font-family: "Cairo Bold";
        }
      }

      .ant-tabs-nav .ant-tabs-nav-list {
        width: 100%;
      }

      .ant-tabs-nav {
        transform: skew(-14deg);

        &::before {
          border: none;
        }
      }
    }
  }
}

/* Mobile View - Font size of the tabs reduced to 14px */
@media (min-width: 320px) and (max-width: 980px) {
  .game {
    padding: 0 1rem;

    &-join-action {
      padding: 1rem 0 0.3rem 0;
      font-size: 14px;
    }

    &-heading {
      font-size: 24px;
      line-height: 28px;
    }
  }

  .game-info-cont-tab .ant-tabs-tab {
    font-size: 14px; // Set tabs font size to 14px in mobile view
    transition: none; // Disable transition for mobile view
    background-color: transparent !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 1px solid yellow;
    outline: none;
    color: white; // Active tab text color
    transition: none; // Disable transition for active tab in mobile view
  }
}

/* Ensure GameTable text color is preserved */
.game-table {
  color: inherit; // Use parent color
  margin-left: -9px;
}
