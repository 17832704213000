@font-face {
  font-family: "Cairo Bold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Bold.ttf");
  font-weight: 700;
}
.rules {
  padding: 2rem 10rem;
  text-align: center;
  margin: auto;
  max-width: 1240px;
  &-master {
    font-size: 16px;
    letter-spacing: 0.31em;
    margin-bottom: 0.5rem;
  }
  &-heading {
    font-size: 40px;
    line-height: 44px;
    width: 100%;
    margin: auto;
  }
  &-objective {
    display: flex;
    padding: 2rem 10rem;
    margin-top: 1rem;
    &-left {
      width: 70%;
      text-align: left;
      &-title {
        font-size: 20px;
        line-height: 28px;
        padding-bottom: 0.5rem;
      }
      &-details {
        font-size: 14px;
        line-height: 20px;
        width: 88%;
      }
    }
    &-right {
      width: 40%;
    }
  }
  &-entergame {
    margin-top: 1rem;
    padding: 2.5rem 9rem;
    text-align: left;
    background-color: #000;
    &-title {
      font-size: 30px;
      margin-bottom: 2rem;
    }
    &-list {
      font-size: 16px;
      &-item {
        margin-bottom: 1rem;
      }
      &-example {
        font-size: 10px;
        line-height: 14px;
        margin-top: 1rem;
      }
    }
    &-must-text {
      font-size: 16px;
      color: #ff1717;
      text-align: center;
    }
  }
  &-game {
    &-title {
      font-size: 32px;
      line-height: 44px;
      margin-bottom: 2rem;
      margin-top: 1rem;
    }
  }
  &-div {
    margin-bottom: 2rem;
    &-compact {
      width: 100%;
      border-radius: 0;
      transform: skew(-15deg);
      align-items: center;
      border: 1px solid #fff017;
      &-input {
        border-radius: 0;
        height: 48px;
        background-color: #000;
        color: #fff;
        border: none;
        background-color: transparent;
      }
      &-button {
        height: 44px;
        border-radius: 0;
        background-color: #fff017;
        color: #000;
        width: 145px;
        padding-top: 8px;
        margin-right: 2px;
      }
    }
  }
  &-referBorder {
    width: 80%;
    border-radius: 0;
    transform: skew(-15deg);
    align-items: center;
    border: 1px solid white;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .rules {
    padding: 1rem 1rem;
    text-align: left;
    &-master {
      font-size: 14px;
      color: #fff017;
      margin-bottom: 0.6rem;
    }
    &-heading {
      font-size: 30px;
      width: 100%;
      line-height: 33px;
    }
    &-objective {
      padding: 0rem;
      flex-direction: column-reverse;
      text-align: center;
      &-left {
        width: 100%;
        margin-top: 3rem;
        &-title {
          font-size: 24px;
        }
        &-details {
          width: 100%;
        }
      }
      &-right {
        display: contents;
      }
    }
    &-entergame {
      padding: 2.5rem 0rem;
      background: rgba(0, 0, 0, 0.2);
      box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(11.6334px);

      &-title {
        font-size: 24px;
      }
      &-list {
        font-size: 14px;
      }
    }
    &-game {
      &-title {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 2rem;
        margin-top: 2rem;
        text-align: center;
      }
    }
    &-referBorder {
      width: 100%;
    }
  }
}
@media (min-width: 1200px) and (max-width: 1320px) {
}
.rulesTop {
  padding-top: 15px;
}
.background-blu {
  background: transparent;
  box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(11.6334px);
}
