@font-face {
  font-family: "Cairo Medium";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Medium.ttf");
  font-weight: 600;
}
.payment {
  max-width: 980px;
  margin: auto;
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: end;
    &-title {
      font-size: 40px;
      line-height: 32px;
      text-align: center;
    }
    &-cont {
      text-align: center;
      display: flex;
      align-items: end;
      &-title {
        font-size: 16px;
        opacity: 0.5;
      }
      &-balance {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  &-cont {
    padding: 2rem 2rem;
    border: 1px solid transparent;
    border-image: linear-gradient(
      180deg,
      #fff017 0%,
      rgba(255, 240, 23, 0) 140.54%
    );
    height: 370px;
    border-image-slice: 1;
    margin: 2rem 0rem;
    &-tab {
      .ant-tabs-ink-bar {
        background-color: transparent;
      }
      .ant-tabs-content-holder {
        background-color: transparent;
      }
      .ant-tabs-tab {
        color: #fff017;
        width: 50%;
        display: flex;
        justify-content: center;
        font-size: 16px;
      }
      .ant-tabs-tab-btn {
        transform: skew(14deg);
      }
      .ant-tabs-tab:hover {
        color: #fff017;
      }
      .ant-tabs-tab + .ant-tabs-tab {
        color: #fff017;
        margin: 0;
      }
      .ant-tabs-tab.ant-tabs-tab-active {
        background-color: #fff017;
        border-bottom: 1px solid yellow;
        outline: none;
        .ant-tabs-tab-btn {
          color: #000;
          font-size: 16px;
          font-family: "Cairo Bold";
        }
      }
      .ant-tabs-nav .ant-tabs-nav-list {
        width: 100%;
      }
      .ant-tabs-nav {
        border: 1px solid #fff017;
        transform: skew(-14deg);
        &::before {
          border: none;
        }
      }
    }
  }
  &-buttons {
    margin: 2rem 0rem;
    text-align: end;
    padding-right: 1rem;
    display: flex;
    justify-content: flex-end;
    &-btnCancel {
      width: 201px;
      margin-right: 12px;
      font-size: 14px;
      height: 45px;
    }
    &-btnUpdate {
      width: 201px;
      font-size: 14px;
    }
  }
  &-field-label {
    font-family: "Cairo Medium";
    color: #fff;
    font-size: 16px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    line-height: 16px;
    display: flex;
    justify-content: space-between;
  }
  &-input-field {
    background-color: #1b1b1b;
    border: 1px solid #1b1b1b;
    height: 51px;
    padding: 0 1rem;
    font-size: 16px;
    font-family: "Cairo Normal";
    border-radius: 0;
    color: #fff;
    width: 100%;
    &:focus {
      border: 1px solid #fff017;
      outline: none;
    }
    &:hover {
      border: 1px solid #fff017;
      outline: none;
    }
    .ant-picker-suffix {
      color: #fff017;
    }
  }
  &-fields {
    display: flex;
    justify-content: space-between;
  }
  .two-fields {
    width: 49%;
  }
  &-error {
    color: red;
    font-size: 12px;
    font-family: "Cairo Medium";
  }
  &-dropdown-filter {
    width: 100%;
    &:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #1b1b1b;
      height: 51px;
      padding: 8px 22px;
      border-radius: 0;
      color: #fff;
      border: none;
    }
    .ant-select-selector .ant-select-selection-item {
      text-align: left;
      font-size: 14px;
      font-family: "Cairo Medium";
      color: #fff;
    }
    &-popupmenu {
      width: 340px !important;
      min-width: 340px !important;
      z-index: 1111;
      .ant-select-item {
        color: #fff;
        border-bottom: 1px solid #aaaaaa;
        border-radius: 0;
        padding: 1rem 0.5rem;
        margin: 0 0.5rem;
        font-family: "Cairo Normal";
      }
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: transparent;
        color: #fff;
      }
    }
  }
  &-button {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
    &-btnsubmit {
      width: 50%;
    }
  }
  &-btnback {
    color: #fff017;
    font-size: 16px;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    &-icon {
      margin-right: 0.2rem;
      svg {
        color: #fff017;
        width: 14px;
        height: 14px;
      }
    }
  }
  &-card {
    &-title {
      font-size: 20px;
      color: #fff;
    }
    &-address {
      color: #fff;
      font-size: 16px;
      line-height: 18px;
      opacity: 0.6;
    }
    &-billing {
      font-size: 20px;
      color: #fff;
      margin-top: 1rem;
    }
    &-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      &-yes {
        background-color: #fff017;
        width: 49%;
        border: none;
      }
      &-acknowledge {
        background-color: #fff017;
      }
      &-no {
        width: 49%;
      }
    }
    &-text {
      margin-top: 2rem;
      color: #fff;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .payment {
    &-title {
      font-size: 30px;
      line-height: 40px;
    }
    &-cont {
      margin: 2rem 0rem;
      padding: 2rem 0.5rem;
      &-tab {
        .ant-tabs-tab {
          width: 46%;
          font-size: 14px;
          border: 1px solid #fff017;
          margin-bottom: 4px;
        }
        .ant-tabs-tab + .ant-tabs-tab {
          color: #fff017;
          margin-left: 4px;
          margin-bottom: 4px;
        }
        .ant-tabs-tab.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            font-size: 14px;
          }
        }
        .ant-tabs-nav .ant-tabs-nav-list {
          flex-wrap: wrap;
          margin-left: 22px;
          div:nth-child(3) {
            margin-left: 0;
          }
        }
        .ant-tabs-nav {
          border: none;
        }
      }
    }
    &-buttons {
      margin: 2rem 0rem;
      display: flex;
      padding-right: 0;
      justify-content: center;
      &-btnCancel {
        width: 140px;
        height: 40px;
      }
      &-btnUpdate {
        width: 140px;
      }
    }
    &-top {
      margin: 0;
    }
  }
}
@media (min-width: 1024px) and (max-width: 1260px) {
  .payment {
    &-title {
      font-size: 56px;
      line-height: 64px;
    }
  }
}
