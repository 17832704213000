.footer {
  display: grid;
  align-items: center;
  // justify-content: space-between;
  padding: 7rem 5rem 2rem 5rem;
  &-right {
    width: 80%;
    display: flex;
  }
  &-logo {
    width: 172px;
    height: 84px;
  }
  &-second {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 2rem 0rem;
    line-height: 22px;
    font-size: 12px;
  }
  &-copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1.5rem;
    font-size: 12px;
    border-top: 1px solid #2e2e2e;
    flex-direction: row-reverse;
  }
  &-navigation {
    width: 80%;
    display: flex;
    justify-content: center;
    padding: 0 8rem;
    &-divs {
      width: 42%;
      // display: grid;
      justify-content: center;
      &-title {
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 1rem;
      }
    }
  }
  &-links {
    // display: grid;
    list-style-type: none;
    text-decoration: none;
    // justify-content: center;
    padding: 0;
    &-list {
      color: #fff;
      text-decoration: none;
      font-family: "Cairo Normal";
      font-size: 16px;
      margin-bottom: 1rem;
      &:hover {
        font-family: "Cairo Bold";
        color: #fff017;
      }
    }
  }
  &-stores {
    &-title {
      font-size: 18px;
      padding-bottom: 2rem;
    }
  }
  &-link {
    color: #fff017;
    &:hover {
      color: #aa9a9a;
    }
  }
  &-nav-items {
    padding-bottom: 1.4rem;
  }
  &-gambling {
    display: flex;
    margin-bottom: 1rem;
    &-div {
      width: 120px;
      height: 34px;
      cursor: pointer;
      margin-right: 2rem;
    }
  }
  &-background {
    background-image: url("../../../assets/images/home_section_footer_.png");
    background-size: 100% 100%;
  }
}
.footer-social-icons {
  width: 160px;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 320px) and (max-width: 480px) {
  .footer {
    padding: 5rem 1rem 0 1rem;
    &-logo {
      width: 100%;
      height: 79px;
      margin-bottom: 1rem;
    }
    &-right {
      width: 100%;
      // display: block;
      flex-direction: column-reverse;
    }
    &-second {
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      padding: 1rem 0;
      text-align: left;
    }
    &-navigation {
      width: 100%;
      padding: 0;
      &-divs {
        width: 45%;
        justify-content: start;
        margin-bottom: 2rem;
        &-title {
          font-size: 18px;
          padding-bottom: 2rem;
        }
      }
    }
    &-stores {
      &-title {
        font-size: 18px;
        padding-bottom: 2rem;
      }
      &-icon {
        width: 190;
        height: 65px;
      }
    }
    &-copyright {
      padding: 1rem 0rem;
      // flex-direction: column;
      &-text {
        text-align: left !important;
        width: 80%;
        margin: auto;
        // padding-left: 30px;
      }
    }
    &-stores {
      margin-right: 0rem;
      text-align: center;
      margin-bottom: 2rem;
    }
    &-gambling {
      flex-wrap: wrap;
      justify-content: center;
      &-div {
        margin-bottom: 0.5rem;
        // width: 48%;
      }
    }
  }
  .d-flex {
    display: grid !important;
  }
}
.footer-social-icons {
  // width: 100%;
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
  &-image {
    margin-left: 8px;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #fff017;
      svg {
        path {
          fill: #000;
        }
      }
    }
    svg {
      path {
        fill: #fff;
      }
    }
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .footer {
    padding: 5rem 1rem 0 1rem;
    &-logo {
      width: 100%;
      height: 79px;
    }
    &-right {
      width: 100%;
    }
    &-second {
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      padding: 1rem 0;
      text-align: left;
    }
    &-navigation {
      display: grid;
      &-divs {
        width: 100%;
        display: grid;
        justify-content: start;
        margin-bottom: 1rem;
        &-title {
          font-size: 18px;
          padding-bottom: 2rem;
        }
      }
    }
    &-links {
      display: grid;
    }
    &-stores {
      &-title {
        font-size: 18px;
        padding-bottom: 2rem;
      }
      &-icon {
        width: 138px;
        height: 43px;
      }
    }
    &-copyright {
      padding: 1rem 0rem;
      display: grid;
      text-align: center;
      &-text {
        width: 80%;
        margin: auto;
      }
    }
  }
  .footer-social-icons {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    &-image {
      margin-left: 8px;
    }
  }
  .d-flex {
    display: grid !important;
  }
  .padding-bottom2 {
    padding-bottom: 1rem !important;
  }
}
