.withdraw {
  &-title {
    color: #fff;
    font-size: 30px;
  }
  &-edit-icon {
    position: absolute;
    top: 15px;
    left: 95%;
  }
  &-sortcode {
    display: flex;
    justify-content: space-between;
  }
  &-sort-field {
    width: 30% !important;
  }
  &-text {
    color: #fff;
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-size: 11px;
  }
  &-info-icon {
    margin-right: 4px;
    svg {
      color: #fff017;
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .residential-address {
    &-title {
      font-size: 24px;
    }
    &-edit-icon {
      position: absolute;
      top: 15px;
      left: 92%;
    }
  }
}
