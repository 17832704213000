@font-face {
  font-family: "Cairo Bold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Cairo Medium";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Medium.ttf");
  font-weight: 600;
}
.cookies-modal {
  color: #fff;
  &-inner {
    width: 50% !important;
    .ant-modal-content {
      border-radius: 0;
      border: 1px solid #fff017;
      background: rgba(0, 0, 0, 0.2);
      box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(11.6334px);
    }
    .ant-modal-header {
      background-color: transparent;
      .ant-modal-title {
        color: #fff;
        font-size: 24px;
        text-align: center;
        font-family: "Cairo Bold";
        margin-bottom: 1rem;
        margin-top: 1.5rem;
      }
    }
  }
}
.cookies {
  &-title {
    font-size: 14px;
    color: #fff;
    line-height: 20px;
    width: 100%;
    margin: auto;
    margin-bottom: 2rem;
  }
  &-collapse {
    border: none;
    .ant-collapse-item {
      background-color: #1b1b1b;
      font-family: "Cairo Medium";
    }
    .ant-collapse-content {
      background-color: #1b1b1b;
      color: #fff;
      border-radius: 0 !important;
      font-family: "Cairo Medium";
    }
    .ant-collapse-header {
      color: #fff !important;
    }
  }
  &-button {
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
    &-item {
      transform: skew(-20deg);
      width: 135px;
      height: 32px;
      border: none;
      color: #000;
      font-family: "Cairo Bold";
      font-size: 14px;
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
      cursor: pointer;
      &-text {
        transform: skew(20deg);
      }
    }
  }
  &-tiltbuttons {
    border: 1px solid #fff017;
    width: 104px;
    height: 29px;
    display: flex;
    transform: skew(-12deg);
    font-size: 14px;
    &-on {
      color: #000;
      background-color: #fff017;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Cairo Bold";
      cursor: pointer;
    }
    &-off {
      color: #fff017;
      background-color: #000;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Cairo Normal";
      cursor: pointer;
    }
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .cookies-modal {
    &-inner {
      width: 100% !important;
    }
  }
  .cookies {
    &-title {
      font-size: 16px;
      line-height: 18px;
    }
    &-buttons {
      &-discard {
        height: 40px;
      }
    }
  }
}
