.places {
  &-dropdown {
    height: 300px;
    border: 1px solid #fff017;
    margin-top: 90px;
    background-color: #161616;
    min-width: 100%;
    position: absolute;
    padding: 0.5rem;
    overflow-y: auto;
    z-index: 11111;
    &-item {
      height: 60px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #d1d1d1;
      cursor: pointer;
      &-text {
        line-height: 16px;
      }
      &-cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.3rem 0;
      }
      &-postcode {
        font-size: 16px;
        color: #fff;
        margin-top: 10px;
      }
      &-details {
        font-size: 14px;
        color: #fff;
        line-height: 14px;
      }
    }
  }
  &-manual-fields {
    display: flex;
    justify-content: space-between;
    height: 82px;
    &-input {
      width: 48%;
      .field100 {
        width: 100%;
        margin-top: 0.8rem;
      }
    }
  }
  &-field {
    width: 100%;
  }
  &-text {
    text-align: end;
    text-decoration: underline;
    color: #fff;
    margin-bottom: 0.8rem;
    &-div {
      cursor: pointer;
      &:hover {
        color: #fff017;
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .places {
    &-dropdown {
      height: 250px;
    }
  }
}
