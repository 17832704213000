.main-home {
  // max-width: 1120px;
  // margin: auto;
  text-align: center;
  &-top-cont {
    background-image: url("../../../assets/images/home_top_section.jpg");
    background-size: 100% 100%;
    height: 90vh;
  }
  &-bottom-cont {
    background-image: url("../../../assets/images/home_bottom_section.jpg");
    background-size: 100% 100%;
  }
  &-top {
    display: flex;
    max-width: 1120px;
    margin: auto;
    padding-top: 4rem;
    margin-bottom: 4rem;
  }
  .main-home-text{
    font-family: "Cairo";
  }
  &-cont {
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  &-text {
    width: 48%;
    padding: 0.5rem 0;
  }
  
}
.mobile-view {
  display: none;
}
.extra-margins {
  margin: 2rem 0;
}
.hey-champ {
  font-size: 22px;
  letter-spacing: 0.28em;
  padding: 1rem 0 0 0;
}
.join-action {
  font-size: 16px;
  letter-spacing: 0.31em;
  padding: 4rem 0 0.3rem 0;
  line-height: 18px;
}
.how-play {
  font-size: 32px;
  line-height: 34px;
  width: 65%;
  margin: auto;
  padding-bottom: 2rem;
}
.master-game {
  font-size: 16px;
  letter-spacing: 0.31em;
  padding: 9rem 0 0.3rem 0;
}
.intro-text {
  font-size: 40px;
  width: 100%;
  margin-bottom: 0.7rem;
  padding-top: 5rem;
}
.custom-end{
  text-align: start;
}
.intro-text h1{
  font-size: 69px;
  color:transparent;
  -webkit-text-stroke: 2px yellow;
  font-family: "Cairo ExtraBold";
}
.intro-text2 {
  font-size: 32px;
  padding-bottom: 2rem;
  width: 90%;
  margin: auto;
  line-height: 36px;
}
.intro-text3 {
  font-size: 72px;
  padding-bottom: 3rem;
  width: 90%;
  margin: auto;
}
.home-store-icons {
  display: flex;
  margin-top: 3rem;
  &-icon {
    margin-right: 6px;
    width: 150px;
  }
}
.home-buttons {
  display: flex;
  justify-content: space-between;
  width: 352px;
  margin-top: 2rem;
  &-btncreate {
    font-size: 14px;
    width: 191px;
    height: 45px;
    z-index: 111;
    &:hover {
      color: #4e4b4b !important;
      border: transparent;
    }
  }
}
.home-join-game-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 6rem;
  max-width: 1120px;
  margin: auto;
}
.home-button-view-rules {
  padding-bottom: 2rem;
}
.home-view-button {
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}
.news-padding {
  padding: 0 3rem;
  max-width: 1120px;
  margin: auto;
}
.home-steps {
  display: flex;
  justify-content: space-between;
  margin: 3rem 4rem 6rem 4rem;
  max-width: 1120px;
  margin: auto;
}
.home-desktoponly {
  display: flex;
}
.slick-dots li button {
  height: 16px !important;
  width: 16px !important;
  border-radius: 50% !important;
  background-color: #fff017 !important;
}
@media (min-width: 320px) and (max-width: 480px) {
  .main-home-text {
    font-family: "Cairo";
    font-size: 16px;
    display: none;
} 
.extra-margin{
  margin-top: 17px;
}
.mobile-hide{
  display: flex;
  flex-direction: column-reverse;
  gap: 0px;
}
.join-action {
  padding: 0px;
}
  .main-home-top-cont{
    height: 116vh;
    justify-content: center !important;
    margin: auto;
  }
  .custom-start {
    text-align: center;
}
  .home-view-button {
    padding: 0px !important
  }
  .mobile-view{
    display: none;
  }
  .custom-end{
    text-align: center;
    // order: 1;
  }
  .intro-text h1 {
    font-size: 45px;
    color: transparent;
    -webkit-text-stroke: 1px yellow;
  }
  .icons-mobile-view {
    margin-top: 17px;
    align-items: center;
    justify-content: center;
}
  .home-store-icons {
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
    &-icon {
      margin-right: 6px;
      width: 200px;
      margin-bottom: 6px;
    }
  }
  .intro-text {
    font-size: 30px;
    width: 96%;
    line-height: 34px;
    text-align: center;
    margin: 1rem 1rem 1.5rem 1rem;
    padding-top: 0;
  }
  .intro-text2 {
    font-size: 24px;
  }
  .main-padding {
    padding: 0 1rem !important;
  }
  .home-buttons {
    display: grid;
    width: 100%;
    margin-top: 2rem;
    height: 128px;
    justify-content: center;
    &-btncreate {
      font-size: 14px;
      width: 235px;
      height: 40px;
      z-index: 111;
      margin-top: 1rem;
    }
  }
  .master-game {
    font-size: 14px;
    padding: 3rem 0 0.5rem 0;
  }
  .how-play {
    font-size: 30px;
    padding-bottom: 0;
  }
  .line-height-73 {
    line-height: 33px !important;
  }
  .line-height-63 {
    line-height: 28px !important;
  }
  .home-join-game-cont {
    justify-content: center;
    padding: 0;
  }
  .padding-x5 {
    padding: 0 3rem !important;
  }
  .home-steps {
    padding: 0;
    display: grid;
    margin: 2rem;
  }
  .join-action {
    font-size: 14px;
  }
  .intro-text2 {
    width: 100%;
  }
  .main-home {
    &-top {
      display: grid;
      margin-top: 0;
      padding: 5px;
    }
    &-text {
      margin-left: 1rem;
    }
  }
  .home-desktoponly {
    display: none;
  }
  .home-button-view-rules {
    margin-top: 1rem;
    padding-bottom: 0.5rem;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
}
@media (min-width: 767px) and (max-width: 1024px) {
}
@media (min-width: 1024px) and (max-width: 1300px) {
  .intro-text2 {
    width: 95%;
  }
  
}
@media (min-width: 1360px) and (max-width: 1600px) {
  .main-home-text {
    width: 100%;
    padding: 0.5rem 0;
    font-size: 19px;

}
  .main-home{
    &-top{
      padding-top: 6rem;
      gap: 36px;
    }
  }
  
  .intro-text {
    margin: 1rem 0 1rem 0;
    padding-top: 2rem;
  }
}
