.matches {
  padding: 4rem 10rem;
  &-teams {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5rem;
    &-line {
      height: 1px;
      width: 75px;
      background-color: #fff;
    }
    &-vs {
      height: 65px;
      width: 65px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      &-icon {
        height: 28px;
        width: 28px;
      }
    }
    &-dropdown {
      top: 100px;
      .ant-dropdown-menu {
        padding: 1px;
        border-radius: 0;
        border: 1px solid #fff017;
        background: rgba(0, 0, 0, 0.2);
        box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(11.6334px);
        max-height: 300px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #fff017;
          &:hover {
            background-color: #fff017;
            scrollbar-width: thin !important;
          }
        }
        .ant-dropdown-menu-item {
          color: #fff;
          border-bottom: 1px solid #d4d4d4;
          padding: 16px 12px;
          margin: 0 8px;
          border-radius: 0;
          font-size: 16px;
          font-family: "Cairo Normal";
        }
      }
      &-icon {
        width: 234px;
        height: 234px;
        border-radius: 222px;
        display: flex;
        justify-content: center;
        align-items: center;
        &-image {
          height: 111px;
          width: 111px;
        }
      }
    }
    &-second {
      .ant-select-selector {
        height: 167px !important;
        width: 167px !important;
        padding: 0 !important;
        border-radius: 50%;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #fff017;
          &:hover {
            background-color: #fff017;
            scrollbar-width: thin !important;
          }
        }
        .ant-select-selection-placeholder {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
        }
      }
      .ant-select-arrow {
        display: none; /* Hide the default arrow icon */
      }
      &-menu {
        padding: 1px;
        border-radius: 0;
        border: 1px solid #fff017;
        background: rgba(0, 0, 0, 0.2);
        box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(11.6334px);
        max-height: 300px;
        overflow-y: auto;
        width: 230px !important;
        .ant-select-item {
          color: #fff;
          border-bottom: 1px solid #d4d4d4;
          padding: 16px 16px;
          margin: 0 8px;
          border-radius: 0;
          font-size: 16px;
          font-family: "Cairo Normal";
        }
      }
    }
  }
  &-details {
    padding: 4rem 6rem 2rem 6rem;
    background-color: #232323;
    color: #fff;
    position: relative;
    &-title {
      display: flex;
      justify-content: space-between;
      padding-bottom: 3rem;
      font-size: 20px;
    }
    &-players {
      &-items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.7rem;
        &-num1 {
          width: 12%;
          font-size: 12px;
        }
        &-name {
          border: 1px solid #fff017;
          border-radius: 16px;
          padding: 0.3rem 0rem;
          width: 150px;
          font-size: 12px;
        }
        &-line {
          height: 1px;
          width: 130px;
          background-color: #fff;
        }
        &-num2 {
          width: 12%;
          font-size: 12px;
        }
      }
      &-lines {
        display: flex;
        align-items: center;
        padding: 0.5rem 0;
      }
    }
    &-cont {
      border: 1px solid #fff017;
      border-radius: 14px;
      height: 72px;
      width: 72px;
      margin: auto;
      display: grid;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;
      margin-top: -110px;
      background-color: #000;
      &-numbers {
        font-size: 32px;
        line-height: 30px;
        align-self: flex-end;
      }
      &-text {
        font-size: 10px;
        align-self: flex-start;
      }
    }
  }
  &-cont-error {
    font-size: 24px;
    width: 70%;
    margin: auto;
    color: #fff017;
    line-height: 28px;
  }
  .ant-select:hover .ant-select-selector {
    border-color: white !important; /* Set border color to white */
  }
}
.teamLogo {
  width: 167px;
  height: 167px;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pickTeam {
  color: white;
  font-family: "Cairo Medium";
  font-size: 20px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .matches {
    padding: 0rem;
    &-teams {
      padding-bottom: 6rem;
      &-dropdown {
        .ant-dropdown-menu {
          width: 180px;
        }
        &-icon {
          height: 77px;
          width: 77px;
        }
      }
      &-line {
        width: 28px;
      }
      &-vs {
        width: 30px;
        height: 30px;
        &-icon {
          height: 16px;
          width: 16px;
        }
      }
      &-second {
        .ant-select-selector {
          height: 80px !important;
          width: 80px !important;
          padding: 0 !important;
          border-radius: 50%;
          .ant-select-selection-placeholder {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            border-color: #fff !important;
          }
        }
        .ant-select-arrow {
          display: none; /* Hide the default arrow icon */
        }
        &-menu {
          padding: 1px;
          border-radius: 0;
          border: 1px solid #fff017;
          background: rgba(0, 0, 0, 0.2);
          box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
          backdrop-filter: blur(11.6334px);
          max-height: 300px;
          overflow-y: auto;
          width: 230px !important;
        }
      }
    }
    &-cont-error {
      font-size: 24px;
      width: 90%;
      line-height: 28px;
    }
    &-details {
      padding: 1rem 1rem 3rem 1rem;
      &-title {
        font-size: 14px;
        padding-bottom: 1rem;
      }
      &-cont {
        height: 46px;
        width: 71px;
        margin-top: -48px;
        &-numbers {
          font-size: 17px;
          line-height: 20px;
        }
        &-text {
          font-size: 10px;
        }
      }
      &-players {
        &-items {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0.1rem;
          &-num1 {
            width: 12%;
          }
          &-name {
            border: 1px solid #fff017;
            border-radius: 16px;
            padding: 0.1rem 0rem;
            width: 91px;
            font-size: 10px;
          }
          &-line {
            height: 1px;
            width: 50px;
            background-color: #fff;
          }
          &-num2 {
            width: 12%;
          }
        }
        &-lines {
          display: flex;
          align-items: center;
          padding: 0.5rem 0;
        }
      }
    }
  }
  .font30 {
    font-size: 14px;
  }
  .teamLogo {
    width: 80px;
    height: 80px;
  }
  .team-icon {
    width: 100px;
    height: 100px;
  }
  .pickTeam {
    font-size: 16px;
  }
}
@media (min-width: 992px) and (max-width: 1280px) {
  .matches {
    padding: 2rem 8rem;
  }
}
