@font-face {
  font-family: "Cairo ExtraBold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-ExtraBold.ttf");
  font-weight: 900;
}
@font-face {
  font-family: "Cairo Medium";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Medium.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Cairo Normal";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Cairo Bold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Bold.ttf");
  font-weight: 700;
}
.resetpassword-title {
  color: #fff;
  font-size: 40px;
  font-family: "Cairo ExtraBold";
  text-align: center;
  margin-bottom: 1.5rem;
  line-height: 44px;
}
.back-to-login {
  text-align: end;
  font-size: 16px;
  font-family: "Cairo Normal";
  padding-bottom: 1.3rem;
  padding-top: -0.3rem;
}
.resetpassword-main-cont {
  display: flex;
  margin: 0 10rem 18rem;
  border: 1px solid #fff017;
}
.resetpassword-form-cont {
  width: 40%;
  padding: 4rem 2rem;
}
.resetpassword-steps-cont {
  width: 55%;
  padding: 3rem 2rem;
  background-image: url("../../../assets/images/bg-signup-right.png");
  background-size: 100% 100%;
}
.resetpassword-already-member {
  font-size: 16px;
  font-family: "Cairo Normal";
  color: #7b7b7b;
  text-align: center;
  padding-top: 1rem;
}
.resetpassword-form-title {
  color: #fff;
  font-size: 18px;
  font-family: "Cairo Medium";
  padding-bottom: 2rem;
}
.resetpassword-fields-cont {
  display: grid;
  height: 110px;
}
.resetpassword-field-label {
  font-family: "Cairo Medium";
  color: #fff;
  font-size: 16px;
  margin-bottom: 1rem;
  line-height: 16px;
}
.resetpassword-input-field {
  background-color: #1b1b1b;
  border: none;
  height: 56px;
  padding: 0 1rem;
  font-size: 16px;
  font-family: "Cairo Normal";

  &:focus {
    border: 1px solid #fff017;
    outline: none;
  }
}
.resetpassword-button-cont {
  text-align: center;
}
.resetpassword-button {
  transform: skew(-20deg);
  width: 95%;
  height: 56px;
  border: none;
  color: #000;
  font-family: "Cairo Bold";
  font-size: 18px;
  margin-top: 1.5rem;
  cursor: pointer;
}
.resetpassword-button-text {
  transform: skew(20deg);
}
.password-icon {
  color: #7b7b7b;
  position: absolute;
  top: 54%;
  right: 4%;
}
.resetpassword-loading-spinner {
  .ant-spin-dot-item {
    background-color: #000;
  }
}
.resetpassword-background {
  background: url("../../../assets/images/bg-auth.jpg");
  background-size: 100% 100%;
}
.resetpassword-password-icon {
  color: #7b7b7b;
  position: absolute;
  top: 51px;
  right: 4%;
  svg {
    color: #fff017 !important;
  }
}
.reset-button {
  font-size: 14px;
  border-radius: 0;
  color: #000;
  border: none;
  z-index: 111;
  background-color: #fff017;
  cursor: pointer;
  &-container5 {
    width: 280px;
    margin: auto;
  }
  &-top-shine5 {
    position: absolute;
    top: -29px;
    left: -50px;
    z-index: 2;
    img {
      height: 56px;
      width: 150px;
    }
  }
  &-bottom-shine5 {
    position: absolute;
    top: 28px;
    left: 182px;
    z-index: 2;
    img {
      height: 56px;
      width: 150px;
    }
  }
  .btntype5 {
    width: 280px;
    height: 56px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .resetpassword-background {
    height: 100vh;
  }
  .resetpassword-title {
    font-size: 30px;
    font-family: "Cairo Bold";
    margin-bottom: 1rem;
    line-height: 38px;
  }
  .resetpassword-steps-cont,
  .resetpassword-form-title {
    display: none;
  }
  .resetpassword-main-cont {
    margin: 2rem 1rem;
    border: 0.5px solid #fff017;
  }
  .resetpassword-form-cont {
    width: 100%;
    padding: 2rem 1rem;
  }
  .resetpassword-fields-cont {
    height: 100px;
  }
  .resetpassword-input-field {
    height: 50px;
  }
  .forget-password {
    padding: 0;
  }
  .resetpassword-button {
    height: 40px;
    font-size: 14px;
  }
  .resetpassword-password-icon {
    top: 46%;
  }
  .back-to-login {
    padding-bottom: 1rem;
  }
  .resetpassword-error-message {
    font-size: 10px;
  }
  .reset-button {
    height: 40px;
    &-container5 {
      width: 280px;
      margin: auto;
    }
    &-top-shine5 {
      position: absolute;
      top: -16px;
      left: -45px;
      z-index: 2;
      img {
        height: 30px;
        width: 130px;
      }
    }
    &-bottom-shine5 {
      position: absolute;
      top: 25px;
      left: 182px;
      z-index: 2;
      img {
        height: 30px;
        width: 120px;
      }
    }
    .btntype5 {
      width: 260px;
      height: 40px;
    }
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .resetpassword-background {
    height: 100vh;
  }
  .resetpassword-title {
    font-size: 30px;
    font-family: "Cairo Bold";
    margin-bottom: 1rem;
  }
  .resetpassword-steps-cont,
  .resetpassword-form-title {
    display: none;
  }
  .resetpassword-main-cont {
    margin: 2rem 1rem;
    border: 0.5px solid #fff017;
  }
  .resetpassword-form-cont {
    width: 100%;
    padding: 2rem 1rem;
  }
  .resetpassword-fields-cont {
    height: 105px;
  }
  .resetpassword-input-field {
    height: 50px;
  }
  .forget-password {
    padding: 0;
  }
  .resetpassword-button {
    height: 40px;
    font-size: 14px;
  }
  .resetpassword-password-icon {
    top: 46%;
  }
  .back-to-login {
    padding: 0;
  }
  .resetpassword-error-message {
    font-size: 10px;
  }
}
@media (min-width: 767px) and (max-width: 992px) {
  .resetpassword-background {
    height: 100vh;
  }
  .resetpassword-title {
    font-size: 30px;
    font-family: "Cairo Bold";
  }
  .resetpassword-steps-cont,
  .resetpassword-form-title {
    display: none;
  }
  .resetpassword-main-cont {
    margin: 2rem 1rem;
    border: 0.5px solid #fff017;
  }
  .resetpassword-form-cont {
    width: 100%;
    padding: 2rem 1rem;
  }
  .resetpassword-fields-cont {
    height: 105px;
  }
  .resetpassword-input-field {
    height: 50px;
  }
  .forget-password {
    padding: 0;
  }
  .resetpassword-button {
    height: 40px;
    font-size: 14px;
  }
  .resetpassword-password-icon {
    top: 46%;
  }
  .back-to-login {
    padding: 0;
  }
  .resetpassword-error-message {
    font-size: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1280px) {
  .resetpassword-button {
    height: 50px;
  }
}
