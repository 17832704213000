.tableHistory {
  width: 100;
  &-competitions {
    display: flex;
    &-icon {
      height: 32px;
      width: 32px;
      margin-right: 8px;
    }
  }
  &-table {
    .ant-table {
      font-size: 14px;
      font-family: "Cairo Normal";
    }
    .ant-table-thead > tr > th {
      text-align: center;
      font-size: 16px;
      font-family: "Cairo Medium";
    }
    .ant-table {
      background-color: #232323;
      color: #fff;
    }
    .ant-table-thead > tr > td,
    .ant-table-thead > tr > th {
      background-color: #1a1800;
      color: #fff;
    }
    .ant-table-cell-fix-left {
      background-color: #232323;
    }
    .ant-table-cell-row-hover {
      background-color: #1b1b1b !important;
    }
    &-outcome {
      display: flex;
      justify-content: space-between;
      &-btn {
        cursor: pointer;
      }
    }
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    text-align: left;
  }
}
.standings-teamHistory {
  display: flex;
  justify-content: center;
}
.laligaContainer {
  display: flex;
  justify-content: space-around;
}
.mask {
  padding-right: 20px;
}
.lal {
  display: flex;
  justify-content: center;
}
.lalText {
  display: flex;
  justify-content: start;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  background-color: #232323 !important;
}
.ant-empty-normal .ant-empty-description {
  color: #fff !important;
}
.activity-history-table {
  .ant-table-thead > tr > th {
    text-align: left;
  }
}
