.banner-mainbox {
  display: flex;
  justify-content: center;
  gap: 3rem;
  padding-top: 3rem;
  font-family: "Cairo normal";
  .banner-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
  .banner-text {
    font-size: 64px;
    color: #fff;
    font-weight: 400;
    line-height: 20px;
    span {
      color: #fff017 !important;
    }
  }
  .promo-btn {
    margin: 0;
  }
  .btntype1 {
    height: 60px !important;
    font-size: 28px;
  }
  .promo-btn.tranform-skew20 {
    transform: skew(-40deg);
  }
  .remove-transform {
    transform: skew(40deg);
  }
  .filled-button-bottom-shine1 {
    position: absolute;
    top: 30px;
    left: 139px;
    z-index: 2;
  }
}

@media (max-width: 456px) {
  .banner-mainbox {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    .banner-text {
      font-size: 32px;
      z-index: 1;
      font-weight: 600;
      letter-spacing: 2px;
    }
  }
  .banner-subtext {
    font-size: 24px !important;
    line-height: 26px;
    z-index: 1;
  }
  .time-unit {
    width: 25px;
    z-index: 1;
  }
  .width {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .banner-mainbox .btntype1 {
    height: 40px !important;
  }
  .banner-mainbox .remove-transform {
    font-size: 20px;
  }
  .banner-mainbox .filled-button-bottom-shine1 {
    top: 14px;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  .banner-box {
    gap: 20px !important;
  }
  .banner-mainbox .banner-text {
    font-size: 27px;
  }
  .banner-subtext {
    font-size: 20px !important;
    line-height: 13px;
  }
  .custom-position {
    left: 155px;
  }
}
