@font-face {
  font-family: "Cairo ExtraBold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-ExtraBold.ttf");
  font-weight: 900;
}
@font-face {
  font-family: "Cairo Medium";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Medium.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Cairo Normal";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Cairo Bold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Bold.ttf");
  font-weight: 700;
}

.phone-number-dropdown {
  border: 2px solid red;
  overflow: hidden;
}
.PhoneInputCountrySelect {
  background-color: #1b1b1b !important ;
}

.signup {
  &-steps {
    &-main {
      display: grid;
      height: 172px;
    }
    &-stepone {
      border-radius: 222px;
      border: 0.5px solid #fff017;
      height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-stepline {
      height: 100px;
      width: 1px;
      background-color: #fff;
      justify-self: center;
    }
    &-steptwo {
      border-radius: 50%;
      border: 0.5px solid #fff017;
      height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-selected-step {
      background-color: #fff017;
      color: #000;
      font-family: "Cairo Bold";
    }
    &-details {
      margin-left: 1.5rem;
      &-stepone {
        height: 138px;
        &-title {
          font-family: "Cairo Medium";
          font-size: 20px;
          margin-bottom: 1rem;
          &:hover {
            color: #fff017;
            cursor: pointer;
          }
        }
        &-description {
          width: 80%;
          font-size: 14px;
          font-family: "Cairo Normal";
        }
      }
      &-steptwo {
        &-title {
          font-family: "Cairo Medium";
          font-size: 20px;
          margin-bottom: 1rem;
        }
        &-description {
          width: 80%;
          font-size: 14px;
          font-family: "Cairo Normal";
        }
      }
    }
  }
  &-info-text {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0.4rem;
  }
  &-callender-icon {
    position: absolute;
    left: 88%;
    z-index: 11;
    top: 14px;
  }
}
.signup-page-title {
  color: #fff;
  font-size: 40px;
  font-family: "Cairo ExtraBold";
  text-align: center;
  margin-bottom: 1.5rem;
  line-height: 44px;
}
.signup-cont {
  display: flex;
  margin: auto;
  border: 1px solid #fff017;
  max-width: 980px;
}
.signup-form-cont {
  width: 42%;
  padding: 4rem 2rem 3rem 2rem;
}
.signup-steps-cont {
  width: 55%;
  padding: 4rem 2rem 3rem 3.5rem;
  background-image: url("../../../assets/images/bg-signup-right.png");
  background-size: 100% 100%;
}
.already-member {
  font-size: 16px;
  font-family: "Cairo Normal";
  color: #7b7b7b;
  text-align: center;
  padding-top: 1rem;
}
.signup-form-title {
  color: #fff;
  font-size: 30px;
  font-family: "Cairo Medium";
  padding-bottom: 1rem;
}
.signup-fields-cont {
  display: grid;
  align-content: baseline;
  // height: 110px;
  height: 82px;
}
.signup-field-label {
  font-family: "Cairo Medium";
  color: #fff;
  font-size: 16px;
  margin-bottom: 0.8rem;
}
.signup-input-field {
  background-color: #1b1b1b;
  border: 1px solid #1b1b1b;
  height: 56px;
  padding: 0 1rem;
  font-size: 16px;
  font-family: "Cairo Normal";
  border-radius: 0;
  color: #fff;
  &:focus {
    border: 1px solid #fff017;
    outline: none;
  }
  .ant-picker-suffix {
    color: #fff017;
  }
  &:-webkit-autofill,
  .your-custom-input-style:-webkit-autofill:hover,
  .your-custom-input-style:-webkit-autofill:focus,
  .your-custom-input-style:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1b1b1b inset !important; /* Adjust the color as needed */
    -webkit-text-fill-color: #fff !important; /* Adjust the color as needed */
  }
}
.error-message {
  color: red;
  font-size: 12px;
  font-family: "Cairo Medium";
}
.signup-button-cont {
  text-align: center;
}
.signupone-button {
  transform: skew(-20deg);
  width: 95%;
  height: 56px;
  background-color: #fff017;
  border: none;
  color: #000;
  font-family: "Cairo Bold";
  font-size: 18px;
  margin-top: 1.7rem;
}
.signup-button-text {
  transform: skew(20deg);
}
.password-icon {
  color: #7b7b7b;
  position: absolute;
  top: 54%;
  right: 4%;
}
.signup-fname-result {
  padding: 0;
  position: absolute;
  top: 13px;
  left: 88.7%;
  .ant-result-icon > .anticon svg {
    font-size: 22px;
    color: #fff017;
  }
}
.signup-fname-error {
  padding: 0;
  position: absolute;
  top: 13px;
  left: 88.7%;
  .ant-result-icon > .anticon svg {
    font-size: 22px;
    color: #ff0000;
  }
}
.mobilesignup-fname-result {
  padding: 0;
  position: absolute;
  top: 45px;
  left: 88.7%;
  .ant-result-icon > .anticon svg {
    font-size: 24px;
    color: #fff017;
  }
}
.signup-username-result {
  padding: 0;
  position: absolute;
  top: 41px;
  left: 88.7%;
  .ant-result-icon > .anticon svg {
    font-size: 24px;
    color: #fff017;
  }
}
.error-message {
  color: red;
  font-size: 14px;
  font-family: "Cairo Medium";
}
.signup-button-cont {
  text-align: center;
  margin-top: 1.9rem;
}
.signup-button {
  transform: skew(-20deg);
  width: 95%;
  height: 56px;
  border: none;
  color: #000;
  font-family: "Cairo Bold";
  font-size: 18px;
  margin-top: 2.5rem;
  cursor: pointer;
}
.signup-button-text {
  transform: skew(20deg);
}
.signup-password-icon {
  color: #7b7b7b;
  position: absolute;
  top: 23px;
  right: 6%;
  svg {
    color: #fff017 !important;
  }
}
.PhoneInputInput {
  background-color: #1b1b1b;
  border: none;
  height: 40px;
  padding: 0 1rem;
  font-size: 16px;
  font-family: "Cairo Normal";
  border-left: 1px solid #dcdcdc;
  color: #fff;
  margin-right: 0.5rem;
  &:focus {
    outline: none;
  }
}
.PhoneInputCountryIcon img {
  border-radius: 222px;
}
.terms-and-conditions {
  font-size: 16px;
  font-family: "Cairo Normal";
  line-height: 1;
  color: #fff;
  .ant-checkbox .ant-checkbox-inner {
    border-color: #fff017 !important;
  }
}
.ui.signup-country-dropdown {
  background-color: #1b1b1b !important;
  color: #fff !important;
  border: 1px solid #1b1b1b;
  height: 56px;
  padding: 0 1rem;
  font-size: 16px !important;
  font-family: "Cairo Normal";
  display: flex !important;
  align-items: center !important;
  &:focus {
    border: 1px solid #fff017;
    outline: none;
  }
  .ant-picker-suffix {
    color: #fff017;
  }
}
.ui.fluid.dropdown > .dropdown.icon {
  top: 1.3rem !important;
}
.ui.selection.active.dropdown {
  border-color: #fff017 !important;
}
.ui.selection.active.dropdown .menu {
  background-color: #000;
  color: #fff;
  border-color: #fff017 !important;
  .selected.item {
    color: #fff017;
  }
  .item {
    color: #fff;
    border-top: 0.5px solid #7b7b7b;
  }
  .ui.selection.visible.dropdown > .text:not(.default) {
    color: #fff;
  }
}
.ui.selection.dropdown > .dropdown.icon {
  padding: 0.916667em 1.2em !important;
}
.ant-picker .ant-picker-input > input {
  color: #fff;
}
.ant-picker .ant-picker-input {
  color: #fff;
}
.anticon svg {
  color: #fff;
}
.PhoneInputCountry {
  margin-right: 22px !important;
  color: #fff !important;
}
.date-of-birth {
  .ant-picker-suffix .anticon svg {
    color: #fff017;
  }
}
::-webkit-calendar-picker-indicator {
  background-image: url("../../../assets/images/callendar-icon.svg");
}
.ui.search.selection.dropdown > input.search {
  line-height: 2.3em !important;
}
.signup-loading-spinner {
  .ant-spin-dot-item {
    background-color: #000;
  }
}
.signup-form-terms {
  font-size: 14px;
  line-height: 14px;
  font-family: "Cairo Normal";
}
.signup-form-checkbox {
  &::after {
    background-color: #fff017;
  }
  &:hover {
    border-color: #fff017;
  }
}
input[type="date"] {
  -webkit-appearance: initial !important;
}
.signup-background {
  background: url("../../../assets/images/bg-auth.jpg");
  background-size: 100% 100%;
  padding-bottom: 10rem;
}
.signup-infomation {
  font-size: 12px;
  font-family: "Cairo Normal";
  color: #7b7b7b;
  line-height: 14px;
  margin-bottom: 12px;
}
.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}
.css-1dimb5e-singleValue {
  color: #fff !important;
}
.signup-terms {
  display: flex;
  align-self: baseline;
  margin-bottom: 0.5rem;
}
.error {
  border: 1px solid red;
}
.cross-icon {
  top: 48px;
}
.checkoxSignup {
  align-self: flex-start;
  margin-top: 2px;
  margin-right: 6px;
}
.react-datepicker-wrapper {
  width: 100%;
}
@media (min-width: 320px) and (max-width: 480px) {
  .signup-fields-main {
    display: flex;
    align-content: baseline;
    height: 105px;
  }
  .signup-infomation {
    font-size: 10px;
  }
  .signup-pdage-title {
    font-size: 30px;
    margin-bottom: 1rem;
  }
  .signup-cont {
    margin: 0 1rem;
  }
  .signup-steps-cont {
    display: none !important;
  }
  .signup-form-cont {
    width: 100%;
    padding: 2rem 1rem;
  }
  .signup-form-title {
    display: none;
  }
  .signup-fields-cont {
    height: 76px;
  }
  .error-message {
    font-size: 10px;
    line-height: 12px;
  }
  .signup-input-field {
    height: 50px;
    width: 100%;
  }
  .signup-fname-result {
    top: 8px;
    left: 89%;
    .ant-result-icon > .anticon svg {
      font-size: 18px;
    }
  }
  .signup-fname-error {
    top: 8px;
    left: 89%;
    .ant-result-icon > .anticon svg {
      font-size: 18px;
    }
  }
  .mobilesignup-fname-result {
    top: 47px;
    left: 89%;
  }
  .signupone-button {
    height: 40px;
    font-size: 14px;
  }
  .d-flex {
    display: flex;
  }
  .signup-page-title {
    font-size: 30px;
    line-height: 32px;
    margin-bottom: 0.7rem;
  }
  .signup-form-checkbox {
    align-self: flex-start;
  }
  .signup-form-terms {
    line-height: 14px;
    font-size: 14px;
  }
  .PhoneInputCountry {
    margin-right: 10px !important;
    color: #fff !important;
  }
  .cross-icon {
    top: 48px;
  }
  .signup-password-icon {
    top: 19px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .signup-fields-main {
    display: flex;
    align-content: baseline;
    height: 105px;
  }
  .signup-infomation {
    font-size: 10px;
  }
  .signup-pdage-title {
    font-size: 30px;
    margin-bottom: 1rem;
  }
  .signup-cont {
    margin: 0 1rem;
  }
  .signup-steps-cont {
    display: none !important;
  }
  .signup-form-cont {
    width: 100%;
    padding: 1rem;
  }
  .signup-form-title {
    display: none;
  }
  .signup-fields-cont {
    height: 105px;
  }
  .error-message {
    font-size: 10px;
    line-height: 12px;
  }
  .signup-input-field {
    height: 50px;
  }
  .signup-fname-result {
    top: 35.3%;
    left: 90%;
    .ant-result-icon > .anticon svg {
      font-size: 18px;
    }
  }
  .signupone-button {
    height: 40px;
    font-size: 14px;
  }
  .d-flex {
    display: flex;
  }
}
@media (min-width: 767px) and (max-width: 992px) {
  .signup-fields-main {
    display: flex;
    align-content: baseline;
    height: 105px;
  }
  .signup-infomation {
    font-size: 10px;
  }
  .signup-pdage-title {
    font-size: 30px;
    margin-bottom: 1rem;
  }
  .signup-cont {
    margin: 0 1rem;
  }
  .signup-steps-cont {
    display: none !important;
  }
  .signup-form-cont {
    width: 100%;
    padding: 1rem;
  }
  .signup-form-title {
    display: none;
  }
  .signup-fields-cont {
    height: 105px;
  }
  .error-message {
    font-size: 10px;
    line-height: 12px;
  }
  .signup-input-field {
    height: 50px;
  }
  .signup-fname-result {
    top: 35.3%;
    left: 90%;
    .ant-result-icon > .anticon svg {
      font-size: 18px;
    }
  }
  .signupone-button {
    height: 40px;
    font-size: 14px;
  }
  .d-flex {
    display: flex;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .signup-fname-result {
    left: 88%;
  }
  .signup-form-terms {
    font-size: 12px;
    line-height: 14px;
  }
}
@media (min-width: 1280px) and (max-width: 1360px) {
  .signup-form-terms {
    font-size: 14px;
    line-height: 14px;
  }
}

/* Error state */
.signup-input-field.error {
  border: 1px solid red !important;
}
.signupCreate {
  padding-bottom: 20px;
}
.underline {
  text-decoration: underline !important;
}

.signup-form-checkbox {
  border-color: yellow !important;
  background-color: #1b1b1b !important;
}
