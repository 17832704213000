@font-face {
  font-family: "Cairo Normal";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Regular.ttf");
}
.private-game {
  padding: 0rem 5rem;
  margin: auto;
  max-width: 940px;
  &-title {
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  &-cont {
    padding: 2rem 2rem;
    &-title {
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      margin-bottom: 1rem;
    }
  }
  &-fields-cont {
    display: block;
    align-content: baseline;
    height: 92px;
    width: 49%;
    &-label {
      font-family: "Cairo Medium";
      color: #fff;
      font-size: 16px;
      margin-bottom: 0.6rem;
      line-height: 30px;
    }
    &-dropdown {
      width: 100%;
      &:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #1b1b1b;
        height: 48px;
        padding: 8px 22px;
        border-radius: 0;
        color: #fff;
        border: none;
      }
      .ant-select-selector .ant-select-selection-item {
        text-align: left;
        font-size: 16px;
        font-family: "Cairo Medium";
        color: #fff;
      }
      .ant-select-selection-placeholder {
        color: #fff;
        opacity: 0.45;
      }
      &-popupmenu {
        width: 300px !important;
        min-width: 300px !important;
        .ant-select-item {
          color: #fff;
          border-bottom: 1px solid #aaaaaa;
          border-radius: 0;
          padding: 1rem 0.5rem;
          margin: 0 0.5rem;
          font-family: "Cairo Normal";
          &:hover {
            color: #fff017;
          }
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background-color: transparent;
          color: #fff;
        }
      }
    }
    &-error-message {
      color: red;
      font-size: 12px;
      font-family: "Cairo Medium";
      line-height: 12px;
    }
  }
  &-button-cont {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    &-continue {
      transform: skew(-20deg);
      width: 30%;
      height: 56px;
      border: none;
      color: #000;
      font-family: "Cairo Bold";
      font-size: 18px;
      cursor: pointer;
    }
    &-discard {
      height: 45px;
      width: 201px;
      margin-right: 12px;
      font-size: 14px;
    }
  }
  &-fields-wrap {
    display: flex;
    justify-content: space-between;
  }
  &-field {
    background-color: #1b1b1b;
    border: 1px solid #1b1b1b;
    height: 48px;
    padding: 0 1rem;
    font-size: 16px;
    font-family: "Cairo Normal";
    border-radius: 0;
    color: #fff;
    &:focus {
      border: 1px solid #fff017;
      outline: none;
    }
    .ant-picker-suffix {
      color: #fff017;
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .private-game {
    padding: 2rem 1rem;
    &-title {
      font-size: 30px;
      line-height: 34px;
    }
    &-cont {
      padding: 2rem 1rem;
      &-title {
        font-size: 24px;
        line-height: 27px;
        margin-bottom: 1.5rem;
      }
    }
    &-button-cont {
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;
      &-continue {
        width: 48%;
        height: 48px;
      }
      &-discard {
        height: 40px;
        width: 151px;
        margin-right: 12px;
      }
    }
    &-last-fields {
      display: block;
    }
    &-last-field {
      width: 100%;
    }
    &-fields-cont {
      &-dropdown {
        &-popupmenu {
          width: 42% !important;
          min-width: 42% !important;
        }
        &-date {
          width: 210px !important;
          min-width: 210px !important;
        }
      }
    }
  }
}
