.privacy-policy {
  padding: 2rem 10rem;
  text-align: center;
  margin: auto;
  max-width: 1240px;
  text-align: left;
  &-heading {
    font-size: 40px;
    line-height: 44px;
    width: 100%;
    margin: auto;
  }
  &-subhead {
    font-size: 14px;
  }
  &-subdescription {
    margin-left: 20px;
  }
  &-subdescription1 {
    margin-left: 40px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .privacy-policy {
    padding: 2rem 1rem;
  }
}
