@font-face {
  font-family: "Cairo Medium";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Medium.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Cairo Bold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Bold.ttf");
  font-weight: 600;
}
.safer-gambling {
  max-width: 980px;
  margin: auto;
  &-title {
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    margin: 1rem 0 2rem 0;
  }
  &-cont {
    padding: 2rem 2rem;
    border: 1px solid transparent;
    border-image: linear-gradient(
      180deg,
      #fff017 0%,
      rgba(255, 240, 23, 0) 140.54%
    );
    height: 470px;
    border-image-slice: 1;
    margin: 2rem 4rem;
    &-tab {
      .ant-tabs-ink-bar {
        background-color: transparent;
      }
      .ant-tabs-content-holder {
        background-color: transparent;
      }
      .ant-tabs-tab {
        color: #fff017;
        width: 25%;
        display: flex;
        justify-content: center;
        font-size: 16px;
      }
      .ant-tabs-tab-btn {
        transform: skew(14deg);
        &:focus-visible {
          outline: none;
        }
      }
      .ant-tabs-tab:hover {
        color: #fff017;
      }
      .ant-tabs-tab + .ant-tabs-tab {
        color: #fff017;
      }
      .ant-tabs-tab.ant-tabs-tab-active {
        background-color: #fff017;
        border-bottom: 1px solid yellow;
        outline: none;
        font-family: "Cairo Bold";
        .ant-tabs-tab-btn {
          color: #000;
          font-size: 16px;
          font-family: "Cairo Bold";
        }
      }
      .ant-tabs-nav .ant-tabs-nav-list {
        width: 100%;
      }
      .ant-tabs-nav {
        border: 1px solid #fff017;
        transform: skew(-14deg);
        &::before {
          border: none;
        }
      }
    }
  }
  &-buttons {
    margin: 2rem 4rem;
    padding-right: 1rem;
    text-align: end;
    display: flex;
    justify-content: flex-end;
    &-confirm {
      font-size: 14px;
      width: 201px;
      height: 45px;
      color: #fff017;
      padding: 0;
      &:hover {
        color: #fff;
      }
    }
    &-cancel {
      width: 201px;
      height: 45px;
      margin-right: 0.5rem;
      font-size: 14px;
      background-color: #fff017;
      border: none;
      &:hover {
        color: #4e4b4b !important;
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .safer-gambling {
    margin: 0 0.5rem;
    &-title {
      font-size: 30px;
      margin-bottom: 1rem;
      line-height: 40px;
    }
    &-cont {
      margin: 1rem 0rem;
      padding: 2rem 0.5rem;
      height: 702px;
      &-tab {
        .ant-tabs-tab {
          width: 46%;
          font-size: 14px;
          border: 1px solid #fff017;
          margin-bottom: 4px;
        }
        .ant-tabs-tab + .ant-tabs-tab {
          color: #fff017;
          margin-left: 4px;
          margin-bottom: 4px;
        }
        .ant-tabs-tab.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            font-size: 14px;
          }
        }
        .ant-tabs-nav .ant-tabs-nav-list {
          flex-wrap: wrap;
          margin-left: 22px;
          div:nth-child(3) {
            margin-left: 0;
          }
        }
        .ant-tabs-nav {
          border: none;
        }
      }
    }
    &-buttons {
      margin:0rem;
      justify-content: center;
      padding-right: 0;
      &-confirm {
        font-size: 14px;
        height: 40px;
        width: 160px;
        padding: 0;
      }
      &-cancel {
        font-size: 14px;
        height: 40px;
        width: 160px;
      }
    }
  }
}
