@font-face {
  font-family: "Cairo Normal";
  font-display: swap;
  src: url("../../../../assets/fonts/Cairo-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Cairo Medium";
  font-display: swap;
  src: url("../../../../assets/fonts/Cairo-Medium.ttf");
  font-weight: 600;
}

.game-table {
  padding: 2.5rem 0rem;
  &-nodata {
    color: #fff !important;
    font-size: 20px;
    text-align: center;
  }
  &-action-button {
    width: 157px;
    background-color: transparent !important;
    font-size: 16px;
    padding: 0;
  }
  .ant-table-tbody {
    font-size: 14px;
  }
  .ant-tabs{
    color: white !important;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    background-color: #1a1800;
    color: #fff;
    border-bottom: none;
    padding-left: 36px;
    cursor: pointer;
  }
  .ant-table-wrapper .ant-table-thead > tr > th:first-of-type {
    padding-left: 12px;
    width: 100px;
    cursor: default;
  }
  .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > {
    background: #1890ff;
  }
  .ant-table-wrapper .ant-table {
    background-color: #232323;
    color: #fff;
    font-size: 16px;
    font-family: "Cairo Normal";
  }
  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:last-child
    > *:last-child {
    border-radius: 0;
    background-color: #1a1800;
    border-bottom: none;
  }
  &-pot-line {
    margin: 12px 0;
    height: 0.5px;
    width: 100%;
    background-color: #7b7b7b;
  }
  &-popover-menu {
    margin-top: 10px;
    .ant-popover-inner {
      padding: 1px;
      border-radius: 0;
      border: 1px solid transparent;
      border-image: linear-gradient(
        180deg,
        #fff017 0%,
        rgba(255, 240, 23, 0) 140.54%
      );
      border-image-slice: 1;
      background: rgba(0, 0, 0, 0.2);
      box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
      -webkit-backdrop-filter: blur(11.6334px);
      backdrop-filter: blur(11.6334px);
      .ant-checkbox-wrapper {
        color: #fff;
        padding: 16px 0;
        border-bottom: 0.5px solid #7b7b7b;
        margin: 0 8px;
        width: 95%;
      }
      .ant-checkbox-wrapper-checked {
        color: #fff017;
      }
      .ant-checkbox-checked .ant-checkbox-inner:hover {
        .ant-checkbox-input {
          border-color: #fff017;
        }
      }
      .ant-popover-inner-content {
        color: #fff;
        padding: 16px 8px !important;
      }
    }
    .ant-popover-arrow {
      display: none !important;
    }
  }
  &-stakeradio {
    .ant-radio-wrapper {
      color: white;
      margin: 8px 0;
      border-bottom: 0.5px solid #7b7b7b;
      padding-bottom: 12px;
      width: 100%;
      .ant-radio-inner {
        border-color: #fff017;
        background-color: #000;
      }
      .ant-radio-inner::after {
        background-color: #fff017;
        width: 25px;
        height: 25px;
        margin-block-start: -12.5px;
        margin-inline-start: -12.5px;
      }
    }
    .ant-radio-wrapper-checked {
      color: #fff017;
    }
  }
  span {
    color: #fff;
    font-family: "Cairo Normal";
  }

  &-sports {
    width: 10.5vw;
    &-checkboxes {
      &-item {
        display: flex;
        align-items: center;
        .ant-checkbox {
          & + span {
            display: flex;
            padding: 0 8px;
            font-size: 14px;
            align-items: center;
          }
        }
        &:checked + .ant-checkbox-inner {
          background-color: #1890ff;
          border-color: #1890ff;
        }
      }
      &-image {
        margin-right: 6px;
        width: 18px;
        height: 18px;
      }
    }
    &-cont {
      display: flex;
      // justify-content: center;
      align-items: center;
      width: 120px;
      &-icon {
        height: 20px;
        width: 20px;
        margin-right: 6px;
      }
    }
  }
  &-competitions {
    width: 15vw;
    &-items {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 16px;
      &-image {
        height: 40px;
        width: 40px;
        border-radius: 222px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
      }
      &-icon {
        height: 34px;
        width: 34px;
      }
    }
  }
  &-deadline {
    width: 13vw;
    .ant-popover-inner-content {
      padding: 24px 8px !important;
    }
    .ant-divider-horizontal {
      margin: 12px 0;
    }
  }
  &-pot {
    width: 13vw;
  }
  &-stake {
    width: 19vw;
    &-slider {
      .ant-slider-rail {
        background-color: #d9d9d9 !important;
      }
      &:hover .ant-slider-track {
        background-color: #fff017;
      }
      &:hover .ant-slider-handle::after {
        background-color: #fff017 !important;
        box-shadow: 0 0 0 2px #fff017 !important;
        border-radius: 50% !important;
      }
      .ant-slider-dot-active {
        display: none;
      }
      .ant-slider-dot {
        display: none;
      }
      .ant-slider-track {
        background-color: #fff017;
      }
      .ant-slider-handle::after {
        background-color: #fff017 !important;
        box-shadow: 0 0 0 2px #fff017 !important;
        border-radius: 50% !important;
      }
      .ant-slider-mark {
        width: 92%;
        margin-top: 6px;
      }
    }
  }
  &-deadline-content {
    display: flex;
    justify-content: center;
    &-cont {
      margin-right: 6px;
      text-align: center;
      &-timer {
        border: 0.2px solid #fff017;
        height: 20px;
        width: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-family: "Cairo Medium";
        border-radius: 4px;
      }
      &-label {
        color: #adadad;
        font-size: 8px;
      }
    }
  }
  .ant-pagination .ant-pagination-item a {
    color: #adadad;
    font-family: "Cairo Normal";
  }
  .ant-pagination .ant-pagination-item-active:hover {
    background-color: #fff017;
    border-radius: 0;
    border: none;
    transform: skew(-5deg);
    color: #000;
    font-family: "Cairo Normal";
    & a:hover {
      color: #000;
    }
  }
  .ant-pagination .ant-pagination-item-active a {
    color: #000;
  }
  .ant-pagination .ant-pagination-next .ant-pagination-item-link {
    .anticon svg {
      color: #fff017;
    }
  }
  .ant-pagination
    .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: #fff017;
  }
  .ant-pagination .ant-pagination-prev button {
    .anticon svg {
      color: #fff017;
    }
  }
  .ant-pagination .ant-pagination-next button {
    color: #fff;
  }
  .ant-pagination
    .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: #fff;
  }
  .ant-pagination .ant-pagination-options {
    display: none;
  }
  .ant-pagination .ant-pagination-item-active {
    background-color: #fff017;
    border-radius: 0;
    border: none;
    transform: skew(-5deg);
    color: #000;

    & a:hover {
      color: #000;
    }
  }
  .ant-table-cell-row-hover {
    background-color: #1b1b1b !important;
  }
  &-turbotag {
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    color: #000;
    border-bottom-right-radius: 12px;
    font-size: 12px;
    padding: 0px 12px;
  }
  &-freetext {
    font-size: 11px;
    color: #7b7b7b;
    text-align: left;
    padding-top: 0.5rem;
  }
}

.game-table-checkbox {
  display: grid;
  .ant-checkbox .ant-checkbox-inner {
    border-radius: 0;
    background-color: #000;
    border: 1px solid #fff017;
    &:hover {
      border: 1px solid #fff017;
      border-color: #fff017;
    }
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: #000;
    border-color: #fff017;
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-inner,
  :where(.css-dev-only-do-not-override-w8mnev).ant-checkbox:not(
      .ant-checkbox-disabled
    ):hover
    .ant-checkbox-inner {
    border-color: #fff017;
  }
  .ant-checkbox .ant-checkbox-inner:after {
    border-bottom: 1px solid #fff017;
    border-right: 1px solid #fff017;
  }
}
.ant-dropdown-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ant-popover-placement-bottom .ant-popover-arrow {
  display: none;
}
.ant-popover .ant-popover-inner {
  border-radius: 0;
}

.competitions-image {
  margin-right: 8px;
}
.ant-table-row-expand-icon-cell {
  display: none;
}
.game-table-row .ant-table-cell:last-child {
  text-align: center;
}

.insights-dropdown-filters {
  background-color: transparent;
  height: 38px;
  padding: 8px 10px;
  border-radius: 0;
  color: #fff;
  border: 1px solid #fff017;
  transform: skew(-20deg);
  align-items: center;
  background-color: #1b1b1b;
}
:where(.css-dev-only-do-not-override-w8mnev).ant- {
  border: none;
  color: white;
  background-color: #1b1b1b;
}
.footballDropdown {
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.ant-select-selector {
  background-color: #161616 !important;
}
.dropdown-filter {
  width: 92px;
  border: none;
  transform: skew(-20deg);
}
.dropdown-filterPremier {
  margin-left: 1rem;
  width: 142px;
  border: none;
  transform: skew(-20deg);
}
.dropdown-filterPot {
  margin-left: 1rem;
  width: 80px;
  border: none;
  transform: skew(-20deg);
}
.dropdown-filterDropdown {
  margin-left: 1rem;
  width: 160px;
  border: none;
  transform: skew(-20deg);
}
.belowDropdown {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-right: 5px;
}
.ant-table-wrapper .ant-table-tbody > tr > td {
  background-color: #232323 !important;
  text-align: center;
}
.ant-empty-normal .ant-empty-description {
  color: #fff !important;
}
@media (min-width: 320px) and (max-width: 480px) {
  .gametable-mobile {
    padding: 0rem;
    &-title {
      font-size: 22px;
      text-align: left;
      margin-left: 1rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff017;
      width: 12px;
    }
    &-cont {
      border: 1px solid #fff017;
      box-shadow: 5px 5px 0px 0px #fff017;
      margin-bottom: 2rem;
      background-color: #000;
      &-items {
        display: flex;
        padding: 1rem;
        border-bottom: 0.5px solid #d4d4d4;
        align-items: center;
        position: relative;
        &-title {
          width: 35%;
          text-align: left;
        }
        &-icon {
          height: 24px;
          width: 24px;
          margin-right: 4px;
        }
      }
    }
    &-button {
      width: 95%;
      padding: 0 0.5rem;
      font-size: 15px;
    }
  }
  .game-table {
    &-stake {
      &-slider {
        .ant-slider-mark {
          width: 87%;
          margin-top: 6px;
        }
      }
    }
    &-popover-menu {
      margin-top: 10px;
      .ant-popover-inner {
        .ant-checkbox-wrapper {
          padding: 8px 0;
          border-bottom: 0.5px solid #7b7b7b;
          margin: 0;
          width: 100%;
        }
        .ant-popover-inner-content {
          color: #fff;
          padding: 16px 8px !important;
        }
      }
    }
    &-turbotag {
      background-color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      left: inherit;
      color: #000;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 0px;
      font-size: 12px;
      padding: 0px 12px;
    }
  }
}
@media (min-width: 480px) and (max-width: 992px) {
  .gametable-mobile {
    padding: 0rem;
    height: 800px;
    overflow-y: auto;
    &-cont {
      border: 1px solid #fff017;
      box-shadow: 5px 5px 0px 0px #fff017;
      margin-bottom: 2rem;
      &-items {
        display: flex;
        padding: 1rem;
        border-bottom: 0.5px solid #d4d4d4;
        align-items: center;
        &-title {
          width: 35%;
        }
      }
    }
    &-button {
      width: 95%;
      padding: 0 0.5rem;
      font-size: 15px;
    }
  }
  .gametable-mobile {
    padding: 0rem;
    height: 800px;
    overflow-y: auto;
    &-title {
      font-size: 22px;
      text-align: left;
      margin-left: 1rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff017;
      width: 12px;
    }
    &-cont {
      border: 1px solid #fff017;
      box-shadow: 5px 5px 0px 0px #fff017;
      margin-bottom: 2rem;
      background-color: #000;
      &-items {
        display: flex;
        padding: 1rem;
        border-bottom: 0.5px solid #d4d4d4;
        align-items: center;
        &-title {
          width: 35%;
          text-align: left;
        }
        &-icon {
          height: 24px;
          width: 24px;
          margin-right: 4px;
        }
      }
    }
    &-button {
      width: 95%;
      padding: 0 0.5rem;
      font-size: 15px;
    }
  }
  .game-table {
    &-stake {
      &-slider {
        .ant-slider-mark {
          width: 87%;
          margin-top: 6px;
        }
      }
    }
    &-popover-menu {
      margin-top: 10px;
      .ant-popover-inner {
        .ant-checkbox-wrapper {
          padding: 8px 0;
          border-bottom: 0.5px solid #7b7b7b;
          margin: 0;
          width: 100%;
        }
        .ant-popover-inner-content {
          color: #fff;
          padding: 16px 8px !important;
        }
      }
    }
  }
}

.filterStyle {
  width: 20px;
  height: 20px;
}
.filterStyleDropdown {
  width: 15px;
  height: 15px;
}
@media only screen and (max-width: 992px) {
  .ant-dropdown-link {
    width: 100%;
    border: 1px solid #fff017;
    transform: skew(-12deg);
    display: flex;
    justify-content: space-around !important;
    height: 38px;
  }
  .game-table-popover-menu .ant-popover-inner .ant-popover-inner-content {
    display: flex;
    border: 1px solid #fff017;
    justify-content: center;
    padding: 0;
    background-color: #161616 !important;
  }
  .ant-popover .ant-popover-inner {
    border: none;
  }
  .game-table {
    &-sports {
      .ant-popover-inner-content {
        width: 120px;
      }
    }
  }
  .ant-popover-inner-content {
    width: 160px;
  }
  .game-table-stake {
    .ant-popover-inner-content {
      width: 180px;
    }
  }
  .popOverContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 10px;
  }
  .popOverSub {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 10px;
  }
  .popOverMain {
    display: flex;
    flex-direction: column !important;
  }
  .popOverContainerMain {
    display: flex;
    flex-direction: column;
  }
  .iconFootball {
    width: 10px;
    height: 10px;
  }
  .centered-popover {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
  }
  .filterStyle {
    width: 15px;
    height: 15px;
  }
}
@media (min-width: 992px) and (max-width: 1280px) {
  .game-table {
    .ant-table-wrapper .ant-table-thead > tr > th {
      padding-left: 24px;
      padding-right: 12px;
    }
  }
}
.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
  outline: none; /* Remove the outline on hover when checked */
}
