.filled-button {
  font-size: 14px;
  border-radius: 0;
  color: #000;
  border: none;
  z-index: 111;
  &-container1 {
    width: 286px;
    margin: auto;
  }
  &-container2 {
    width: 151px;
  }
  &-container3 {
    width: 201px;
  }
  &-container4 {
    width: 145px;
  }
  &-container5 {
    width: 280px;
    margin: auto;
  }
  &-container6 {
    width: 110px;
  }
  &-container7 {
    width: 201px;
  }
  &-top-shine1 {
    position: absolute;
    top: -29px;
    left: -74px;
    z-index: 2;
    img {
      height: 56px;
      width: 200px;
    }
  }
  &-bottom-shine1 {
    position: absolute;
    top: 16px;
    left: 162px;
    z-index: 2;
    img {
      height: 56px;
      width: 200px;
    }
  }
  &-top-shine2 {
    position: absolute;
    top: -29px;
    left: -72px;
    z-index: 2;
    img {
      height: 56px;
      width: 200px;
    }
  }
  &-bottom-shine2 {
    position: absolute;
    top: 16px;
    left: 28px;
    z-index: 2;
    img {
      height: 56px;
      width: 200px;
    }
  }
  &-top-shine3 {
    position: absolute;
    top: -29px;
    left: -72px;
    z-index: 2;
    img {
      height: 56px;
      width: 200px;
    }
  }
  &-bottom-shine3 {
    position: absolute;
    top: 16px;
    left: 75px;
    z-index: 2;
    img {
      height: 56px;
      width: 200px;
    }
  }
  &-top-shine4 {
    position: absolute;
    top: -29px;
    left: -72px;
    z-index: 2;
    img {
      height: 56px;
      width: 200px;
    }
  }
  &-top-shine5 {
    position: absolute;
    top: -29px;
    left: -50px;
    z-index: 2;
    img {
      height: 56px;
      width: 150px;
    }
  }
  &-bottom-shine4 {
    position: absolute;
    top: 16px;
    left: 2px;
    z-index: 2;
    img {
      height: 56px;
      width: 200px;
    }
  }
  &-bottom-shine5 {
    position: absolute;
    top: 28px;
    left: 200px;
    z-index: 2;
    img {
      height: 56px;
      width: 150px;
    }
  }
  &-bottom-shine5 {
    position: absolute;
    top: 28px;
    left: 184px;
    z-index: 2;
    img {
      height: 56px;
      width: 150px;
    }
  }
  &-bottom-shine5 {
    position: absolute;
    top: 28px;
    left: 184px;
    z-index: 2;
    img {
      height: 56px;
      width: 150px;
    }
  }
  &-top-shine6 {
    position: absolute;
    top: -21px;
    left: -32px;
    z-index: 2;
    img {
      height: 40px;
      width: 100px;
    }
  }
  &-bottom-shine6 {
    position: absolute;
    top: 25px;
    left: 41px;
    z-index: 2;
    img {
      height: 40px;
      width: 100px;
    }
  }
  &-top-shine7 {
    position: absolute;
    top: -29px;
    left: -72px;
    z-index: 2;
    img {
      height: 56px;
      width: 200px;
    }
  }
  &-bottom-shine7 {
    position: absolute;
    top: 16px;
    left: 75px;
    z-index: 2;
    img {
      height: 56px;
      width: 200px;
    }
  }
  &:hover {
    color: #4e4b4b !important;
  }
  &:disabled {
    background-color: #fff017;
  }
}

.btntype1 {
  width: 286px;
  height: 45px;
}
.btntype2 {
  width: 151px;
  height: 45px;
}
.btntype3 {
  width: 201px;
  height: 45px;
}
.btntype4 {
  width: 125.5px;
  height: 44px;
}
.btntype5 {
  width: 280px;
  height: 56px;
  font-size: 18px;
}
.btntype6 {
  width: 110px;
  height: 45px;
}
.btntype7 {
  width: 201px;
  height: 45px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .filled-button {
    font-size: 14px;
    border-radius: 0;
    color: #000;
    border: none;
    z-index: 111;
    &-container1 {
      width: 235px;
      margin: auto;
    }
    &-container2 {
      width: 180px;
      margin: auto;
    }
    &-container3 {
      width: 140px;
    }
    &-container5 {
      width: 260px;
    }
    &-container4 {
      width: 125px;
    }
    &-container7 {
      width: 160px;
    }
    &-top-shine1 {
      left: -52px;
      top: -26px;
      img {
        height: 50px;
        width: 150px;
      }
    }
    &-bottom-shine1 {
      top: 14px;
      left: 140px;
      img {
        height: 50px;
        width: 150px;
      }
    }
    &-top-shine2 {
      position: absolute;
      top: -21px;
      left: -28px;
      z-index: 2;
      img {
        height: 40px;
        width: 110px;
      }
    }
    &-bottom-shine2 {
      position: absolute;
      top: 20px;
      left: 100px;
      z-index: 2;
      img {
        height: 40px;
        width: 110px;
      }
    }
    &-top-shine3 {
      top: -21px;
      left: -20px;
      img {
        height: 40px;
        width: 90px;
      }
    }
    &-bottom-shine3 {
      top: 20px;
      left: 70px;
      img {
        height: 40px;
        width: 90px;
      }
    }
    &-bottom-shine5 {
      top: 20px;
      left: 170px;
      img {
        height: 40px;
        width: 120px;
      }
    }
    &-top-shine5 {
      left: -32px;
      img {
        height: 56px;
        width: 120px;
      }
    }
    &-top-shine4 {
      position: absolute;
      top: -15px;
      left: -33px;
      z-index: 2;
      img {
        height: 30px;
        width: 100px;
      }
    }
    &-bottom-shine4 {
      position: absolute;
      top: 25px;
      left: 43px;
      z-index: 2;
      img {
        height: 30px;
        width: 100px;
      }
    }
    &-top-shine7 {
      top: -20.5px;
      left: -20px;
      img {
        height: 40px;
        width: 90px;
      }
    }
    &-bottom-shine7 {
      top: 20px;
      left: 98px;
      img {
        height: 40px;
        width: 90px;
      }
    }
  }
  .btntype1 {
    width: 235px;
    height: 40px;
  }
  .btntype2 {
    width: 180px;
    height: 40px;
  }
  .btntype3 {
    width: 140px;
    height: 40px;
  }
  .btntype5 {
    width: 260px;
  }
  .btntype4 {
    width: 110px;
    height: 36px;
  }
  .btntype3 {
    width: 160px;
    height: 40px;
  }
}
.flex {
  display: flex;
}
