@font-face {
  font-family: "Cairo Medium";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Medium.ttf");
  font-weight: 600;
}
.insights {
  max-width: 1240px;
  margin: auto;
  &-intro-text {
    font-size: 40px;
    line-height: 44px;
    width: 80%;
    margin: 0.3rem auto 0 auto;
  }
  &-hey-champ {
    font-size: 16px;
    letter-spacing: 0.28em;
    padding: 1rem 0 0 0;
    color: #fff017;
  }
  &-standings-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem 1.5rem 0rem;
    &-filter {
      margin-left: 6px;
    }
    &-text {
      font-size: 32px;
    }
  }
  &-standings {
    padding: 2rem 2rem;
    border: 1px solid #fff017;
    height: 800px;
    overflow-y: scroll;
    &-dropdowns {
      display: flex;
    }
  }
  &-basketballstandings {
    height: 1000px;
    overflow-y: scroll;
  }
  &-fixtures-title {
    margin-top: 2rem;
    padding: 0 2rem;
    line-height: 63px;
    &-text {
      font-size: 32px;
      line-height: 36px;
      text-align: left;
      padding-top: 2rem;
      padding-bottom: 1rem;
    }
  }
  &-fixtures {
    padding: 2rem 2rem;
    border: 1px solid #fff017;
  }
  &-results-title {
    margin-top: 2rem;
    padding: 0 2rem;
    line-height: 63px;
  }
  &-matches {
    padding: 0rem 1rem;
  }
  &-dropdown-filter {
    margin-left: 1rem;
    width: 165px;
    &:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent;
      height: 35px;
      padding: 8px 22px;
      border-radius: 0;
      color: #fff;
      border-color: #fff017 !important;
      transform: skew(-20deg);
      &:hover {
        border-color: #fff017 !important;
      }
    }
    .ant-select-selector .ant-select-selection-item {
      text-align: left;
      transform: skew(20deg);
      font-size: 14px;
      font-family: "Cairo Medium";
      color: #fff;
      align-self: center;
    }
    &-popupmenu {
      background: rgba(0, 0, 0, 0.2);
      box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(11.6334px);
      border-radius: 0;
      border: 1px solid #fff017;
      width: 160px !important;
      min-width: 160px !important;
      .ant-select-item {
        color: #fff;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .insights {
    &-hey-champ {
      font-size: 14px;
      text-align: left;
    }
    &-fixtures-title {
      padding: 0;
    }
    &-intro-text {
      font-size: 30px;
      width: 100%;
      margin: 0.3rem auto 2rem auto;
      text-align: left;
    }
    &-standings-title {
      padding: 0;
      margin-bottom: 1rem;
      &-filter {
        margin-left: 6px;
      }
      &-text {
        font-size: 24px;
      }
    }
    &-dropdown-filter {
      margin-left: 1rem;
      width: 48%;
      &:not(.ant-select-customize-input) .ant-select-selector {
        background-color: transparent;
        height: 38px;
        padding: 8px 10px;
        border-radius: 0;
        color: #fff;
        border-color: #fff017;
        transform: skew(-20deg);
        align-items: center;
      }
      .ant-select-selector .ant-select-selection-item {
        text-align: left;
        transform: skew(20deg);
        font-size: 12px;
        font-family: "Cairo Medium";
        line-height: 14px;
      }
      &-popupmenu {
        background: rgba(0, 0, 0, 0.2);
        box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(11.6334px);
        border-radius: 0;
        border: 1px solid #fff017;
        width: 140px !important;
        min-width: 140px !important;
        .ant-select-item {
          color: #fff;
        }
      }
    }
    &-fixtures {
      padding: 1rem;
      &-title {
        &-text {
          font-size: 24px;
          padding-top: 1rem;
        }
      }
    }
    &-standings {
      padding: 1rem;
    }
  }
  .font55 {
    font-size: 24px !important;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .insights {
    &-matches {
      padding: 2rem 0.8rem;
    }
  }
}
