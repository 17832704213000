.join-game {
  width: 49%;
  border: 1px solid #fff017;
  position: relative;
  margin-bottom: 5rem;
  &-image {
    height: 140px;
  }
  &-cont {
    padding: 1rem 1rem 3rem 1rem;
    &-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      &-title {
        display: flex;
        align-items: center;
        &-icon {
          height: 24px;
          width: 24px;
          border-radius: 50%;
          margin-right: 8px;
        }
        &-text {
          font-size: 15px;
        }
      }
      &-button {
        &-btn {
          background-color: transparent;
          border-radius: 0;
          color: #fff;
          height: 57px;
          width: 209px;
          font-size: 20px;
        }
      }
    }
    &-bottom {
      display: flex;
      justify-content: space-between;
      width: 100%;
      &-left {
        display: flex;
        align-items: center;
        font-size: 13px;
        &-icon {
          height: 20px;
          width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 5px;
        }
      }
      &-right {
        display: flex;
        align-items: center;
        font-size: 13px;
        &-icon {
          height: 20px;
          width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 5px;
        }
      }
    }
  }
  &-button {
    margin: 2rem 0;
    position: absolute;
    bottom: -45px;
    left: 25.5%;
    &-class {
      width: 190px;
      height: 33px;
      &:hover {
        border-color: #fff;
        color: #fff;
      }
    }
  }
  &-timer {
    display: flex;
    width: 125px;
    justify-content: space-between;
    &-cont {
      border: 0.35px solid #fff017;
      border-radius: 6px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      padding: 0.5px 0;
    }
    &-text {
      font-size: 9px;
      color: #adadad;
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .join-game {
    width: 100%;
    margin-bottom: 3rem;
    padding-bottom: 2rem;
    &-cont {
      padding: 1rem;
      &-top {
        border-bottom: 0.5px solid #d4d4d4;
        padding-bottom: 1rem;
        &-title {
          &-icon {
            height: 24px;
            width: 24px;
          }
          &-text {
            font-size: 17px;
          }
        }
        &-button {
          &-btn {
            height: 57px;
            width: 209px;
            font-size: 20px;
          }
        }
      }
      &-bottom {
        display: flex;
        width: 100%;
        &-left {
          font-size: 18px;
          &-icon {
            height: 23px;
            width: 23px;
            &-image {
              height: 18px;
              width: 8px;
            }
          }
        }
        &-right {
          font-size: 18px;
          &-icon {
            height: 23px;
            width: 23px;
            &-image {
              height: 18px;
              width: 8px;
            }
          }
        }
      }
    }
    &-button {
      bottom: -48px;
      left: 22%;
      &-class {
        padding: 0;
        width: 150px;
        height: 38px;
      }
    }
    &-timer {
      width: 115px;
      &-cont {
        width: 34px;
        font-size: 14px;
        padding: 1px 0;
      }
      &-text {
        font-size: 10px;
      }
    }
    &-button {
      left: 21%;
    }
  }
  .padding-x8 {
    padding: 0 4rem !important;
  }
  .padding-top4 {
    padding-top: 2rem !important;
  }
  .padding-bottom8 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 400px) and (max-width: 480px) {
  .join-game {
    width: 375px;
    &-button {
      bottom: -46px;
      left: 97px;
      &-class {
        padding: 0;
        width: 180px;
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 400px) {
  .join-game {
    width: 310px;
    &-button {
      left: 80px;
    }
  }
}
@media (min-width: 1024px) and (max-width: 1100px) {
  .join-game {
    &-button {
      left: 20.5%;
      &-class {
        padding: 0 5.5rem;
      }
    }
    &-cont {
      padding: 1.5rem 1.5rem 3rem 1.5rem;
      &-top {
        &-title {
          &-icon {
            height: 32px;
            width: 32px;
          }
          &-text {
            font-size: 20px;
          }
        }
      }
    }
    &-timer {
      display: flex;
      width: 200px;
      justify-content: space-between;
      &-cont {
        width: 60px;
        font-size: 18px;
      }
      &-text {
        font-size: 14px;
        color: #adadad;
      }
    }
  }
}
@media (min-width: 1100px) and (max-width: 1200px) {
  .join-game {
    &-button {
      left: 21%;
      &-class {
        padding: 0 6rem;
      }
    }
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .join-game {
    &-button {
      left: 25%;
      &-class {
        // padding: 0 7rem;
      }
    }
  }
}
