.residential-address {
  margin-top: 0.5rem;
  &-title {
    color: #fff;
    font-size: 24px;
  }
  &-edit-icon {
    position: absolute;
    top: 15px;
    left: 95%;
  }
}
.css-13cymwt-control {
  background-color: #1b1b1b !important;
  border-radius: 0 !important;
  border: none !important;
  height: 56px !important;
}
@media (min-width: 320px) and (max-width: 480px) {
  .residential-address {
    &-title {
      font-size: 24px;
    }
    &-edit-icon {
      position: absolute;
      top: 15px;
      left: 92%;
    }
  }
}
