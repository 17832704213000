.main-background1 {
  background: url("../../assets/images/Home\ V2.jpg");
  background-size: 100% 100%;
}
.main-background2 {
  background: url("../../assets/images/bg-image2.png");
  background-size: 100% 100%;
}
.main-background3 {
  background: url("../../assets/images/bg-image3.png");
  background-size: 100% 100%;
}

.placeholder {
  background-color: #000; /* Adjust the color as needed */
  width: 100%;
  height: 100vh; /* Adjust height as needed */
}
