.deposit-limits {
    margin-top: 0.5rem;
    color: white;
    &-text {
      font-size: 24px;
    }
    &-description {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 0.8rem;
    }
    &-title {
      font-size: 20px;
      margin-bottom: 0.4rem;
    }
    &-edit-icon {
      position: absolute;
      top: 45px;
      left: 95%;
    }
    &-dropdowns {
      &-label {
        font-size: 16px;
        margin-bottom: 0.4rem;
        margin-top: 0.6rem;
      }
    }
    &-dropdown-filter {
      width: 100%;
      &:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #1b1b1b;
        height: 48px;
        padding: 8px 22px;
        border-radius: 0;
        color: #fff;
        border: none;
      }
      .ant-select-selector .ant-select-selection-item {
        text-align: left;
        font-size: 14px;
        font-family: "Cairo Medium";
        color: #fff;
      }
      &-popupmenu {
        width: 226px !important;
        min-width: 226px !important;
        z-index: 1111;
        .ant-select-item {
          color: #fff;
          border-bottom: 1px solid #aaaaaa;
          border-radius: 0;
          padding: 1rem 0.5rem;
          margin: 0 0.5rem;
          font-family: "Cairo Normal";
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background-color: transparent;
          color: #fff;
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .deposit-limits {
      margin-top: 1rem;
      &-text {
        font-size: 24px;
        margin-bottom: 1rem;
      }
      &-edit-icon {
        top: 43px;
        left: 90%;
      }
    }
  }
  