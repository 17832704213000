.bordered-button {
  height: 66px;
  border-radius: 0;
  color: #fff017;
  padding: 0 3.5rem;
  border-color: #fff017;
  z-index: 111;
  &:not(:disabled):hover {
    color: #fff;
    border-color: #fff017;
  }
}
