@font-face {
  font-family: "Cairo Bold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Bold.ttf");
  font-weight: 700;
}
.alert-form-modal {
  &-inner {
    width: 40% !important;
    .ant-modal-content {
      border-radius: 0;
      border: 1px solid #fff017;
      background: rgba(0, 0, 0, 0.2);
      box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(11.6334px);
    }
    .ant-modal-header {
      background-color: transparent;
      .ant-modal-title {
        color: #fff;
        font-size: 40px;
        text-align: center;
        font-family: "Cairo Bold";
        margin-bottom: 2.5rem;
        margin-top: 1.5rem;
      }
    }
  }
}
.alert {
  &-title {
    font-size: 24px;
    color: #fff;
    line-height: 28px;
    width: 100%;
    margin: auto;
    text-align: center;
  }
  &-buttons {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    &-discard {
      width: 151px;
      height: 45px;
      margin-right: 8px;
      padding: 0;
      z-index: 1111;
    }
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .alert-form-modal {
    &-inner {
      width: 100% !important;
    }
  }
  .alert {
    &-title {
      font-size: 16px;
      line-height: 18px;
    }
    &-buttons {
      &-discard {
        height: 40px;
      }
    }
  }
}
