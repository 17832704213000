.all-selections {
  max-width: 1240px;
  margin: auto;
  &-title {
    text-align: center;
    font-size: 40px;
    line-height: 44px;
  }
  &-button {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  &-main {
    margin: 1rem 7rem;
    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
      margin-right: 1rem;
    }
    &-title {
      font-size: 32px;
      line-height: 36px;
    }
  }
  &-tables {
    &-percents {
      margin-bottom: 2rem;
      &-trophy {
        width: 180px;
        height: 190px;
        margin: auto;
        position: relative;
        background-image: url("../../../assets/images/trphy.svg");
        background-size: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &-numbers {
          color: #000;
          font-size: 20px;
        }
      }
      &-title {
        font-size: 20px;
      }
    }
    &-down {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
    }
    &-breakdown {
      width: 48%;
      &-cont {
        width: 100%;
        border: 1.5px solid #fff017;
        border-radius: 8px;
        padding: 2rem 1rem;
        height: 360px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #fff017;
          width: 12px;
          padding-top: 20px;
          &:hover {
            background-color: #fff017;
          }
        }
        &-bar {
          background-color: #fff017;
          height: 14px;
          transform: skew(-20deg);
        }
        &-line {
          height: 43px;
          border-top: 1px solid #fff !important;
          border-right: 1px solid #fff !important;
          align-self: center;
          position: absolute;
          top: 50%;
        }
        &-div {
          width: 50%;
          display: flex;
          position: relative;
        }
        &-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0.6rem;
          height: 34px;
          &-image {
            margin-right: 6px;
            height: 24px;
            width: 24px;
          }
          &-name {
            display: flex;
            font-size: 14px;
            width: 30%;
            line-height: 16px;
            align-items: center;
          }
          &-figure {
            font-size: 18px;
          }
        }
      }
      &-title {
        font-size: 20px;
        margin-bottom: 0.5rem;
      }
    }
    &-distribution {
      width: 48%;
      &-cont {
        width: 100%;
        border: 1.5px solid #fff017;
        border-radius: 8px;
        padding: 2rem;
        position: relative;
        height: 360px;
        &-loading {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &-title {
        font-size: 30px;
        margin-bottom: 1.5rem;
      }
      &-divs {
        position: relative;
        border: 1px solid #fff017;
        position: absolute;
        border-radius: 222px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #fff017;
        // padding: 9px;
        font-size: 18px;
        &-icon {
          height: 24px;
          width: 24px;
        }
      }
      .size1 {
        width: 150px;
        height: 150px;
      }
      .size2 {
        width: 120px;
        height: 120px;
      }
      .size3 {
        width: 90px;
        height: 90px;
      }
      .size4 {
        width: 80px;
        height: 80px;
      }
      .size5 {
        width: 60px;
        height: 60px;
      }
      .div1 {
        top: 25%;
        left: 8%;
      }
      .div2 {
        top: 12%;
        left: 42%;
      }
      .div3 {
        top: 56%;
        left: 38%;
      }
      .div4 {
        top: 22%;
        left: 73%;
      }
      .div5 {
        top: 46%;
        left: 60%;
      }
      &-showteam {
        position: absolute;
        background-color: #000;
        top: -20px;
        display: flex;
        width: 200px;
        color: #fff;
        align-items: center;
        font-size: 16px;
        justify-content: center;
        &-icon {
          margin-right: 8px;
          height: 24px;
          width: 24px;
        }
      }
    }
  }
  &-dropdown-filter {
    margin-left: 1rem;
    width: 210px;
    &:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent;
      height: 40px;
      padding: 8px 22px;
      border-radius: 0;
      color: #fff;
      border: 1px solid transparent;
      border-image: linear-gradient(
        0deg,
        #fff017 0%,
        rgba(255, 240, 23, 0) 106.54%
      );
      border-image-slice: 1;
      transform: skew(-20deg);
      align-self: center;
    }
    .ant-select-selector .ant-select-selection-item {
      text-align: left;
      transform: skew(20deg);
      font-size: 14px;
      font-family: "Cairo Medium";
      color: #fff;
      align-self: center;
    }
    &-popupmenu {
      background: rgba(0, 0, 0, 0.2);
      box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(11.6334px);
      border-radius: 0;
      border: 1px solid #fff017;
      .ant-select-item {
        color: #fff !important;
        background-color: #000 !important;
      }
    }
    .ant-select-selector {
      border-color: #fff017 !important;
    }
  }
  .ant-select-focused {
    border-color: #fff017 !important;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .mobile-percentage{
    font-size: 15px;
  }
  .all-selections {
    &-main {
      margin: 2rem 1rem;
      &-title {
        width: 100%;
        font-size: 30px;
        line-height: 30px;
        border: 1px solid #fff017;
        border-radius: 20px;
        margin-bottom: 1rem;
        height: 67px;
        display: flex;
        align-items: center;
        padding-left: 1rem;
      }
      &-top {
        flex-direction: column;
        margin-right: 0;
        &-dropdown {
          width: 100%;
        }
      }
    }
    &-dropdown-filter {
      width: 97%;
      margin-left: 0.5rem;
    }
    margin-top: 3rem;
    &-title {
      text-align: left;
      margin-left: 1rem;
    }
    &-table {
      margin: 1rem;
      &-title {
        font-size: 24px;
        line-height: 27px;
        margin-bottom: 1rem;
      }
    }
    &-tables {
      &-down {
        flex-direction: column;
      }
      &-breakdown {
        width: 100%;
        margin-bottom: 1rem;
        &-title {
          font-size: 18px;
          margin-bottom: 0.5rem;
          margin-top: 1rem;
        }
        &-cont {
          padding: 2rem 0.5rem;
          &-bar {
            background-color: #fff017;
            height: 14px;
            transform: skew(-20deg);
          }
          &-line {
            height: 43px;
            border-top: 1px solid #fff;
            border-right: 1px solid #fff !important;
            align-self: center;
            position: absolute;
            top: 50%;
          }
          &-div {
            width: 60%;
            display: flex;
            position: relative;
          }

          &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0.6rem;
            &-image {
              margin-right: 4px;
              height: 20px;
              width: 20px;
            }
            &-name {
              font-size: 14px;
              width: 30%;
              display: flex;
              align-items: start;
              line-height: 17px;
            }
            &-figure {
              font-size: 18px;
            }
          }
        }
      }
      &-distribution {
        width: 100%;
      }
      &-percents {
        &-trophy {
          margin-top: 1rem;
        }
        &-title {
          font-size: 18px;
        }
      }
    }
    .div1 {
      top: 25%;
      left: 8%;
    }
    .div2 {
      top: 8%;
      left: 40%;
    }
    .div3 {
      top: 65%;
      left: 25%;
    }
    .div4 {
      top: 24%;
      left: 71%;
    }
    .div5 {
      top: 52%;
      left: 62%;
    }
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .all-selections {
    margin-top: 3rem;
    &-title {
      text-align: left;
      margin-left: 1rem;
    }
    &-table {
      margin: 1rem;
      &-title {
        font-size: 24px;
        line-height: 27px;
        margin-bottom: 1rem;
      }
    }
  }
}
@media (min-width: 1440px) {
}

.count-width {
  width: 10% !important;
  text-align: center !important;
}

.no-border-right {
  border-right: none !important;
}
