.masterCard {
  padding-right: 10px;
}
.withdraw {
  &-table {
    .ant-table {
      font-size: 14px;
      font-family: "Cairo Normal";
    }
    .ant-table-thead > tr > th {
      text-align: left;
      font-size: 16px;
      font-family: "Cairo Medium";
      padding: 16px 12px;
    }
    .ant-table-tbody > tr > td:not(:first-child) {
      text-align: left;
    }
    .ant-table {
      background-color: #232323;
      color: #fff;
    }
    .ant-table-thead > tr > td,
    .ant-table-thead > tr > th {
      background-color: #1a1800;
      color: #fff;
    }
    .ant-table-cell-fix-left {
      background-color: #232323;
    }
    .ant-table-cell-row-hover {
      background-color: #1b1b1b !important;
    }
  }
}
