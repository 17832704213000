@font-face {
  font-family: "Cairo ExtraBold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-ExtraBold.ttf");
  font-weight: 900;
}
@font-face {
  font-family: "Cairo Medium";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Medium.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Cairo Normal";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Cairo Bold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Bold.ttf");
  font-weight: 700;
}
.forgetpassword-title {
  color: #fff;
  font-size: 40px;
  font-family: "Cairo ExtraBold";
  text-align: center;
  margin-bottom: 1.5rem;
  line-height: 44px;
}
.forget-password-text {
  text-align: end;
  font-size: 16px;
  font-family: "Cairo Normal";
  padding-bottom: 1rem;
}
.forgetpassword-main-cont {
  display: flex;
  margin: 0 10rem 18rem;
  border: 1px solid #fff017;
}
.forgetpassword-form-cont {
  width: 40%;
  padding: 4rem 2rem;
}
.forgetpassword-steps-cont {
  width: 55%;
  padding: 3rem 2rem;
  background-image: url("../../../assets/images/bg-signup-right.png");
  background-size: 100% 100%;
}
.already-member {
  font-size: 16px;
  font-family: "Cairo Normal";
  color: #7b7b7b;
  text-align: center;
  padding-top: 1.6rem;
}
.forgetpassword-form-title {
  color: #fff;
  font-size: 18px;
  font-family: "Cairo Medium";
  padding-bottom: 2rem;
}
.forgetpassword-fields-cont {
  display: grid;
  height: 80px;
}
.field-label {
  font-family: "Cairo Medium";
  color: #fff;
  font-size: 16px;
  margin-bottom: 1rem;
  line-height: 16px;
}
.login-input-field {
  background-color: #1b1b1b;
  border: none;
  height: 56px;
  padding: 0 1rem;
  font-size: 16px;
  font-family: "Cairo Normal";
}
.forgetpassword-button-cont {
  text-align: center;
}
.login-button {
  transform: skew(-20deg);
  width: 95%;
  height: 56px;
  border: none;
  color: #000;
  font-family: "Cairo Bold";
  font-size: 18px;
  margin-top: 1.5rem;
  cursor: pointer;
}
.forgetpassword-button-text {
  transform: skew(20deg);
}
.password-icon {
  color: #7b7b7b;
  position: absolute;
  top: 54%;
  right: 4%;
}
.login-loading-spinner {
  .ant-spin-dot-item {
    background-color: #000;
  }
}
.login-background {
  background: url("../../../assets/images/bg-auth.jpg");
  background-size: 100% 100%;
}
@media (min-width: 320px) and (max-width: 480px) {
  .forgetpassword-title {
    font-size: 30px;
    font-family: "Cairo Bold";
    margin-bottom: 1rem;
    line-height: 46px;
  }
  .forgetpassword-steps-cont,
  .forgetpassword-form-title {
    display: none;
  }
  .forgetpassword-main-cont {
    margin: 2rem 1rem;
    border: 0.5px solid #fff017;
  }
  .forgetpassword-form-cont {
    width: 100%;
    padding: 2rem 1rem;
  }
  .forgetpassword-fields-cont {
    height: 76px;
  }
  .resetpassword-input-field {
    height: 50px;
  }
  .forget-password {
    padding: 0;
  }
  .login-button {
    height: 40px;
    font-size: 14px;
  }
  .resetpassword-password-icon {
    top: 46%;
  }
  .back-to-login {
    padding: 0;
  }
  .resetpassword-error-message {
    font-size: 10px;
  }
  .login-input-field {
    height: 50px;
  }
  .error-message {
    font-size: 10px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .forgetpassword-title {
    font-size: 30px;
    font-family: "Cairo Bold";
    margin-bottom: 1rem;
    line-height: 46px;
  }
  .forgetpassword-steps-cont,
  .forgetpassword-form-title {
    display: none;
  }
  .forgetpassword-main-cont {
    margin: 2rem 1rem;
    border: 0.5px solid #fff017;
  }
  .forgetpassword-form-cont {
    width: 100%;
    padding: 2rem 1rem;
  }
  .forgetpassword-fields-cont {
    height: 100px;
  }
  .resetpassword-input-field {
    height: 50px;
  }
  .forget-password {
    padding: 0;
  }
  .login-button {
    height: 40px;
    font-size: 14px;
  }
  .resetpassword-password-icon {
    top: 46%;
  }
  .back-to-login {
    padding: 0;
  }
  .resetpassword-error-message {
    font-size: 10px;
  }
  .login-input-field {
    height: 50px;
  }
  .error-message {
    font-size: 10px;
  }
}
@media (min-width: 767px) and (max-width: 992px) {
  .forgetpassword-title {
    font-size: 30px;
    font-family: "Cairo Bold";
    margin-bottom: 1rem;
    line-height: 46px;
  }
  .forgetpassword-steps-cont,
  .forgetpassword-form-title {
    display: none;
  }
  .forgetpassword-main-cont {
    margin: 2rem 1rem;
    border: 0.5px solid #fff017;
  }
  .forgetpassword-form-cont {
    width: 100%;
    padding: 2rem 1rem;
  }
  .forgetpassword-fields-cont {
    height: 100px;
  }
  .resetpassword-input-field {
    height: 50px;
  }
  .forget-password {
    padding: 0;
  }
  .login-button {
    height: 40px;
    font-size: 14px;
  }
  .resetpassword-password-icon {
    top: 46%;
  }
  .back-to-login {
    padding: 0;
  }
  .resetpassword-error-message {
    font-size: 10px;
  }
  .login-input-field {
    height: 50px;
  }
  .error-message {
    font-size: 10px;
  }
}
.custom-input-field:focus {
  border: none !important;
}
.no-outline:focus {
  outline: none; /* Remove the outline when focused */
  /* You can also add any other styles you want for the focused state here */
}
