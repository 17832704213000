@font-face {
  font-family: "Cairo Normal";
  font-display: swap;
  src: url("../../../../assets/fonts/Cairo-Regular.ttf");
  font-weight: 400;
}
.all-selection-table {
  padding: 2.5rem 2rem;
  border-radius: 20px;
  &-text {
    position: absolute;
    top: 93%;
    font-size: 16px;
    font-family: "Cairo Normal";
  }
  .ant-table-tbody {
    padding: 12px;
  }
  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    text-align: left;
  }
  .ant-table-wrapper .table {
    width: 400px;
    overflow-x: scroll;
  }
  .ant-table-wrapper .ant-table {
    background-color: #232323;
    color: #fff;
    font-size: 16px;
    font-family: "Cairo Normal";
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    font-size: 14px;
    border-bottom: 0.5px solid #d4d4d4;
    padding: 16px 16px;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    background-color: #191800;
    color: #fff017;
    border-bottom: none;
    // width: 180px;
    text-align: center;
  }
  .ant-table-cell-fix-left {
    background-color: #232323;
  }
  .ant-pagination .ant-pagination-item a {
    color: #adadad;
  }
  .ant-pagination .ant-pagination-item-active:hover {
    background-color: #fff017;
    border-radius: 0;
    border: none;
    transform: skew(-5deg);
    color: #000;
    & a:hover {
      color: #000;
    }
  }
  .ant-pagination .ant-pagination-item-active a {
    color: #000;
  }
  .ant-pagination .ant-pagination-next .ant-pagination-item-link {
    .anticon svg {
      color: #fff017;
    }
  }
  .ant-pagination
    .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: #fff017;
  }
  .ant-pagination .ant-pagination-prev button {
    .anticon svg {
      color: #fff017;
    }
  }
  .ant-pagination .ant-pagination-next button {
    color: #fff;
  }
  .ant-pagination
    .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: #fff;
  }
  .ant-pagination .ant-pagination-options {
    display: none;
  }
  .ant-pagination .ant-pagination-item-active {
    background-color: #fff017;
    border-radius: 0;
    border: none;
    transform: skew(-5deg);
    color: #000;
    & a:hover {
      color: #000;
    }
  }
  &-icons{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.ant-dropdown-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ant-popover-placement-bottom .ant-popover-arrow {
  display: none;
}
.filterSelection {
  background-color: #161616;
  border-bottom: 1px solid #fff017 !important;
  width: 179px;
  height: 36px;
  border-radius: 7.65px;
  border-top: none;
  border-left: none;
  border-right: none;
  color: white;
  opacity: 0.6;
}
.filterSelection::placeholder {
  font-family: "Cairo Normal";
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.025em;
  text-align: left;
  color: #ffffff;
}
.filterAllSelection {
  height: 15px;
  position: absolute;
  left: 170px;
  margin-top: 10px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .all-selection-table {
    padding: 1rem;
  }
  .ant-table-cell-fix-left {
    background-color: #232323;
    box-shadow: 10px 0 10px 0px rgba(0, 0, 0, 0.2);
  }
  .ant-table-wrapper .ant-table {
    font-size: 12px;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    font-size: 12px;
  }

  .ant-table-wrapper .ant-table-pagination-right {
    justify-content: center;
  }
  .filterSelection {
    width: 100%;
    height: 28px;
  }
  .filterAllSelection {
    left: 79%;
  }
  .filterAllSelection {
    margin-top: 6px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .all-selection-table {
    padding: 1rem;
    .ant-table-cell-fix-left {
      background-color: #232323;
      box-shadow: 10px 0 10px 0px rgba(0, 0, 0, 0.2);
    }
    .ant-table-wrapper .ant-table {
      font-size: 12px;
    }
    .ant-table-wrapper .ant-table-tbody > tr > td {
      font-size: 12px;
    }
  }
  .ant-table-wrapper .ant-table-pagination-right {
    justify-content: center;
  }
  .filterSelection {
    width: 79px;
    height: 28px;
    border-radius: 7.65px;
  }
}
@media (min-width: 767px) and (max-width: 1300px) {
  .all-selection-table {
    padding: 1rem;
    .ant-table-cell-fix-left {
      background-color: #232323;
      box-shadow: 10px 0 10px 0px rgba(0, 0, 0, 0.2);
    }
  }
}
