.probability {
  padding: 2rem 1rem;
  &-title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 1rem;
    color: #fff017;
  }
  &-cont {
    display: flex;
    width: 100%;
    &-image {
      height: 24px;
      width: 24px;
    }
    &-bar {
      display: flex;
      width: 88%;
      &-win {
        height: 24px;
        background-color: blue;
        display: flex;
        justify-content: center;
        font-size: 10px;
        align-items: center;
      }
      &-draw {
        height: 24px;
        background-color: grey;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
      }
      &-lose {
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: red;
        font-size: 10px;
      }
    }
  }
  &-loading {
    text-align: center;
    &-spinner {
      .ant-spin-dot-item {
        background-color: #fff017;
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .probability {
    padding: 1rem 0rem;
    &-title {
      font-size: 20px;
      margin-bottom: 0.5rem;
    }
    &-cont {
      display: flex;
      width: 100%;
      align-items: baseline;
      &-image {
        height: 20px;
        width: 20px;
      }
      &-bar {
        display: flex;
        width: 88%;
        &-win {
          height: 20px;
          font-size: 8px;
        }
        &-draw {
          height: 20px;
          font-size: 8px;
        }
        &-lose {
          height: 20px;
          font-size: 8px;
        }
      }
    }
  }
}
