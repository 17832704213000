.referral {
  padding: 2rem 7rem;
  position: relative;
  margin-bottom: 15%;
  &-cont {
    text-align: center;
    padding: 3.5rem 5rem 8rem 5rem;
    &-image {
      margin: auto;
      width: 65px;
      height: 65px;
      background-color: #d9d9d9;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      &-icon {
        height: 32px;
        width: 32px;
      }
    }
    &-title {
      font-size: 40px;
      line-height: 44px;
      margin-bottom: 0.8rem;
    }
    &-refertext {
      font-size: 16px;
    }
    &-terms {
      font-size: 16px;
      &-link {
        text-decoration: underline;
        color: #fff;
        &:hover {
          color: #fff017;
        }
      }
    }
  }
  &-bottom {
    width: 72%;
    margin: auto;
    padding: 2rem 4rem 1rem 4rem;
    position: absolute;
    top: 75%;
    left: 14%;
    &-div {
      &-compact {
        width: 100%;
        border-radius: 0;
        transform: skew(-15deg);
        align-items: center;
        border: 1px solid #fff017;
        &-input {
          border-radius: 0;
          height: 48px;
          background-color: #000;
          color: #fff;
          border: none;
          background-color: transparent !important;
        }
        &-button {
          height: 44px;
          border-radius: 0;
          background-color: #fff017;
          color: #000;
          width: 145px;
          font-size: 14px;
          margin-right: 2px;
        }
      }
    }
    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
      &-copy {
        display: flex;
        border-bottom: 1px solid #b3b3b3;
        padding-bottom: 0.4rem;
        align-items: center;
        font-size: 14px;
        margin-top: 0.5rem;
      }
      &-button {
        font-size: 14px;
        width: 69px;
        padding: 0;
        margin-left: 1rem;
        &:hover {
          color: #f9f59a !important;
          border-color: #f9f59a !important;
        }
      }
      &-share {
        margin-right: 0.8rem;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  &-socials {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 2rem auto 1rem auto;
    &-cont {
      display: flex;
      align-items: center;
      &-icon {
        margin-right: 8px;
      }
    }
    &-name {
      font-size: 17px;
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .referral {
    padding: 1rem;
    margin-bottom: 50%;
    &-cont {
      padding: 1rem;
      &-image {
        width: 73px;
        height: 73px;
        margin-bottom: 1rem;
        &-icon {
          height: 34px;
          width: 34px;
        }
      }
      &-title {
        font-size: 30px;
        line-height: 34px;
        margin-bottom: 1rem;
      }
      &-refertext {
        font-size: 16px;
        margin-bottom: 1.5rem;
      }
      &-terms {
        margin-bottom: 8rem;
        font-size: 16px;
      }
    }
    &-bottom {
      width: 88%;
      left: 6%;
      padding: 1.5rem;
      &-div {
        &-compact {
          &-input {
            height: 44px;
          }
          &-button {
            height: 40px;
            width: 100px;
            font-size: 15px;
          }
        }
      }
      &-link {
        display: grid;
        justify-content: flex-start;
        &-copy {
          width: 75vw;
          justify-content: space-between;
        }
      }
    }
    &-socials {
      flex-wrap: wrap;
      width: 100%;
      &-cont {
        width: 40%;
        margin-bottom: 0.8rem;
      }
      &-name {
        width: 70%;
      }
    }
  }
}
.font {
  font-family: "Cairo Normal";
}
.inviteHover:hover {
  background-color: yellow !important;
  color: black !important;
}

.referBorder {
  width: 100%;
  border-radius: 0;
  transform: skew(-15deg);
  align-items: center;
  border: 1px solid white;
}

.refrelMain {
  width: 100%;
  display: flex;
  justify-content: center;
}
.font::placeholder {
  color: #ffff !important;
}
.pointer{
  cursor: pointer;
}