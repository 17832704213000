@font-face {
  font-family: "Cairo ExtraBold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-ExtraBold.ttf");
  font-weight: 900;
}
@font-face {
  font-family: "Cairo Medium";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Medium.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Cairo Normal";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Cairo Bold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Bold.ttf");
  font-weight: 700;
}
.login-page-title {
  color: #fff;
  font-size: 40px;
  line-height: 44px;
  font-family: "Cairo ExtraBold";
  text-align: center;
  margin-bottom: 1.5rem;
}
.forget-password {
  text-align: end;
  font-size: 16px;
  font-family: "Cairo Normal";
  padding-bottom: 0.5rem;
}
.main-cont {
  display: flex;
  margin: 0 10rem 18rem;
  border: 1px solid #fff017;
}
.main-cont2 {
  max-width: 1240px;
  margin: auto;
}
.login-form-cont {
  width: 45%;
  padding: 4rem 2rem;
}
.login-steps-cont {
  width: 55%;
  padding: 3rem 2rem;
  background-image: url("../../../assets/images/bg-signup-right.png");
  background-size: 100% 100%;
}
.already-member {
  font-size: 16px;
  font-family: "Cairo Normal";
  color: #7b7b7b;
  text-align: center;
  padding-top: 1rem ;
}
.login-form-title {
  color: #fff;
  font-size: 30px;
  font-family: "Cairo Medium";
  padding-bottom: 2rem;
}
.fields-cont {
  display: grid;
  align-content: baseline;
  height: 82px;
}
.field-label {
  font-family: "Cairo Medium";
  color: #fff;
  font-size: 16px;
  margin-bottom: 0.8rem;
}
.login-input-field {
  background-color: #1b1b1b;
  border: none;
  height: 56px;
  padding: 0 1rem;
  font-size: 16px;
  font-family: "Cairo Normal";
  &:-webkit-autofill,
  .your-custom-input-style:-webkit-autofill:hover,
  .your-custom-input-style:-webkit-autofill:focus,
  .your-custom-input-style:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1b1b1b inset !important; /* Adjust the color as needed */
    -webkit-text-fill-color: #fff !important; /* Adjust the color as needed */
  }

  &:focus {
    border: 1px solid #fff017;
    outline: none;
  }
}
.login-button-cont {
  text-align: center;
}
.login-button {
  transform: skew(-20deg);
  width: 95%;
  height: 56px;
  border: none;
  color: #000;
  font-family: "Cairo Bold";
  font-size: 18px;
  margin-top: 1.5rem;
  cursor: pointer;
}
.signup-button-text {
  transform: skew(20deg);
}
.login-password-icon {
  color: #7b7b7b;
  position: absolute;
  top: 22px;
  right: 4%;
  svg {
    color: #fff017 !important;
  }
}
.login-loading-spinner {
  .ant-spin-dot-item {
    background-color: #000;
  }
}
.login-background {
  background: url("../../../assets/images/bg-auth.jpg");
  background-size: 100% 100%;
  height: 120vh;
}
@media (min-width: 320px) and (max-width: 480px) {
  .login-background {
    height: 100vh;
  }
  .login-page-title {
    font-size: 30px;
    font-family: "Cairo Bold";
    line-height: 38px;
    margin-bottom: 1rem;
  }
  .login-steps-cont,
  .login-form-title {
    display: none;
  }
  .main-cont {
    margin: 2rem 1rem;
    border: 0.5px solid #fff017;
  }
  .login-form-cont {
    width: 100%;
    padding: 2rem 1rem;
  }
  .fields-cont {
    height: 100px;
  }
  .login-input-field {
    height: 50px;
  }
  .forget-password {
    padding: 0;
  }
  .login-button {
    height: 40px;
    font-size: 14px;
  }
  .login-password-icon {
    top: 19px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .login-background {
    height: 100vh;
  }
  .login-page-title {
    font-size: 30px;
    font-family: "Cairo Bold";
    line-height: 38px;
    margin-bottom: 1rem;
  }
  .login-steps-cont,
  .login-form-title {
    display: none;
  }
  .main-cont {
    margin: 2rem 1rem;
    border: 0.5px solid #fff017;
  }
  .login-form-cont {
    width: 100%;
    padding: 2rem 1rem;
  }
  .fields-cont {
    height: 100px;
  }
  .login-input-field {
    height: 50px;
  }
  .forget-password {
    padding: 0;
  }
  .login-button {
    height: 40px;
    font-size: 14px;
  }
}
@media (min-width: 767px) and (max-width: 992px) {
  .login-background {
    height: 100vh;
  }
  .login-page-title {
    font-size: 30px;
    font-family: "Cairo Bold";
  }
  .login-steps-cont,
  .login-form-title {
    display: none;
  }
  .main-cont {
    margin: 2rem 1rem;
    border: 0.5px solid #fff017;
  }
  .login-form-cont {
    width: 100%;
    padding: 2rem 1rem;
  }
  .fields-cont {
    height: 100px;
  }
  .login-input-field {
    height: 50px;
  }
  .forget-password {
    padding: 0;
  }
  .login-button {
    height: 40px;
    font-size: 14px;
  }
}
@media (min-width: 992px) and (max-width: 1280px) {
  .login-form-title {
    line-height: 24px;
    font-size: 24px;
  }
  .login-button {
    height: 50px;
  }
}
.forgetFlex {
  display: flex;
  justify-content: end;
}
.styleFoget {
  padding-bottom: 13px;
}
.fields-contPass {
  display: grid;
  align-content: baseline;
  height: 72px;
}
.loginWidth {
  width: 280px;
}
.loginFlexx {
  display: flex;
  justify-content: center;
}
.login-input-field.error {
  border: 1px solid red;
}
.login-input-field {
  border: none;
}
