@font-face {
  font-family: "Cairo ExtraBold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-ExtraBold.ttf");
  font-weight: 900;
}
@font-face {
  font-family: "Cairo Medium";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Medium.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Cairo Normal";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Cairo Bold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Bold.ttf");
  font-weight: 700;
}

.promoCode-fields-cont {
  display: grid;
  align-content: baseline;
  // height: 110px;
  height: 82px;
}
.error-message {
  color: red;
  font-size: 12px;
  font-family: "Cairo Medium";
}

.promo-input-field {
  background-color: #161616;
  border: 1px solid #1b1b1b;
  height: 50px;
  width: 100%; /* Adjust the width as needed */
  padding: 0 1rem;
  font-size: 16px;
  font-family: "Cairo Normal";
  border-radius: 0;
  color: #fff;
  position: relative;
  &:focus {
    border: 1px solid #fff017;
    outline: none;
  }
  .ant-picker-suffix {
    color: #fff017;
  }
  &:-webkit-autofill,
  .your-custom-input-style:-webkit-autofill:hover,
  .your-custom-input-style:-webkit-autofill:focus,
  .your-custom-input-style:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1b1b1b inset !important;
    -webkit-text-fill-color: #fff !important;
  }
}

.promo-fname-result {
  padding: 0;
  position: absolute;
  top: 8px;
  left: 59%;
  .ant-result-icon > .anticon svg {
    font-size: 18px;
    color: #fff017;
  }
}
.in-line-input-btn {
  display: flex;
  justify-content: space-between;
}
.promo-fname-error {
  padding: 0;
  position: absolute;
  top: 6px;
  left: 60%;
  .ant-result-icon > .anticon svg {
    font-size: 15px;
    color: #ff0000;
  }
}
.mobile-promo-fname-result {
  padding: 0;
  position: absolute;
  top: 45px;
  left: 88.7%;
  .ant-result-icon > .anticon svg {
    font-size: 24px;
    color: #fff017;
  }
}
.promo-username-result {
  padding: 0;
  position: absolute;
  top: 41px;
  left: 88.7%;
  .ant-result-icon > .anticon svg {
    font-size: 24px;
    color: #fff017;
  }
}
.error-message {
  color: red;
  font-size: 14px;
  font-family: "Cairo Medium";
}
input[type="date"] {
  -webkit-appearance: initial !important;
}
.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}
.css-1dimb5e-singleValue {
  color: #fff !important;
}
.error {
  border: 1px solid red;
}
.cross-icon {
  position: absolute;
  top: 48px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
}
@media (min-width: 320px) and (max-width: 480px) {
  //this is there
  .promoCode-fields-cont {
    height: 76px;
  }
  .error-message {
    font-size: 10px;
    line-height: 12px;
  }
  .promo-input-field {
    height: 40px;
    width: 100%; /* Adjust the width as needed for mobile */
  }
  .promo-fname-result {
    top: 8px;
    left: 62%;
    .ant-result-icon > .anticon svg {
      font-size: 18px;
    }
  }
  .promo-fname-error {
    top: 8px;
    left: 62%;
    .ant-result-icon > .anticon svg {
      font-size: 18px;
    }
  }
  .mobile-promo-fname-result {
    top: 47px;
    left: 89%;
  }
  .d-flex {
    display: flex;
  }

  .cross-icon {
    top: 48px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .promoCode-fields-cont {
    height: 105px;
  }
  .error-message {
    font-size: 10px;
    line-height: 12px;
  }
  .promo-fname-result {
    top: 8px;
    left: 89%;
    .ant-result-icon > .anticon svg {
      font-size: 18px;
    }
  }
}
@media (min-width: 767px) and (max-width: 992px) {
  .promoCode-fields-cont {
    height: 105px;
  }
  .error-message {
    font-size: 10px;
    line-height: 12px;
  }
  .promo-fname-result {
    top: 8px;
    left: 66%;
    .ant-result-icon > .anticon svg {
      font-size: 14px;
    }
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .promo-fname-result {
    top: 8px;
    left: 60%;
    .ant-result-icon > .anticon svg {
      font-size: 14px;
    }
  }
}
// .game-promo-div{
//   position: relative;
// }
.promo-apply-text {
  font: 10px;
  position: absolute;
  right: 4%;
  top: 15%;
  color: yellow;
  cursor: pointer;
}
