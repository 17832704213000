@font-face {
    font-family: "Cairo Bold";
    font-display: swap;
    src: url("../../../assets/fonts/Cairo-Bold.ttf");
    font-weight: 700;
  }
  .insufficientbalance-modal {
    &-inner {
      width: 50% !important;
      .ant-modal-content {
        border-radius: 0;
        border: 1px solid #fff017;
        background: rgba(0, 0, 0, 0.2);
        box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(11.6334px);
      }
      .ant-modal-header {
        background-color: transparent;
        .ant-modal-title {
          color: #fff;
          font-size: 28px;
          text-align: center;
          font-family: "Cairo Bold";
          margin-bottom: 1rem;
          margin-top: 1.5rem;
        }
      }
    }
    &-details {
      color: #fff;
      line-height: 20px;
      font-size: 16px;
      text-align: center;
    }
    &-buttons {
      display: flex;
      justify-content: end;
      margin: 1rem 10px 0 0;
      &-confirm {
        font-size: 14px;
        width: 201px;
        height: 45px;
        color: #fff017;
        padding: 0;
        &:hover {
          color: #fff;
        }
        margin-right: 8px;
      }
      &-cancel {
        width: 201px;
        height: 45px;
        margin-right: 0.5rem;
        font-size: 14px;
        background-color: #fff017;
        border: none;
        &:hover {
          color: #4e4b4b !important;
        }
      }
    }
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
    .insufficientbalance-modal {
      .ant-modal-title {
        font-size: 24px !important;
        margin-bottom: 0.5rem !important;
        margin-top: 1rem !important;
        line-height: 28px;
      }
      &-inner {
        width: 90% !important;
        .ant-modal-content {
          border-radius: 0;
          border: 1px solid #fff017;
          background: rgba(0, 0, 0, 0.2);
          box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
          backdrop-filter: blur(11.6334px);
          padding: 20px 8px;
        }
      }
    }
    .verification-warning-form-modal {
      &-inner {
        width: 100% !important;
        max-width: 94%;
      }
    }
  }
  