@font-face {
  font-family: "Cairo Normal";
  font-display: swap;
  src: url("../../../../assets/fonts/Cairo-Regular.ttf");
  font-weight: 400;
}
.ongoing-table {
  &-private-tag {
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    color: #000;
    border-bottom-right-radius: 12px;
    font-size: 12px;
    padding: 0px 12px;
  }
  .ant-table-wrapper .ant-table {
    background-color: #000;
    color: #fff;
    font-size: 14px;
    font-family: "Cairo Normal";
    background-color: #000;
  }
  .ant-table-cell {
    font-size: 14px;
    background-color: #1a1800 !important;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    background-color: #1a1800;
    color: #fff;
    border-bottom: none;
    font-size: 16px;
  }
  &-competitions {
    width: 145px !important;
    font-size: 14px;
    display: flex;
    align-items: center;
    &-image {
      width: 36px;
      height: 36px;
      margin-right: 8px;
    }
  }
  &-entries {
    &-cont {
      display: flex;
      &-number {
        height: 25px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        margin-right: 4px;
      }
    }
    &-centerline {
      height: 25px;
      width: 1px;
      background-color: #fff017;
      margin-right: 8px;
      margin-left: 4px;
      transform: rotate(16deg);
    }
  }
  &-mobile-actions {
    display: grid;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    margin: auto;
    &-cont {
      width: 100%;
      line-height: 22px;
    }
  }
  &-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
    margin: auto;
    &-cont {
      width: 55%;
      line-height: 22px;
    }
    &-title {
      color: #fff017;
      margin-bottom: 6px;
    }
    &-button {
      width: 110px;
      padding: 0 0.5rem;
      font-size: 12px;
      color: #fff;
    }
    &-btnview {
      width: 110px;
      padding: 0 0.5rem;
      font-size: 12px;
      color: #fff;
      height: 38px !important;
      &:hover {
        color: #fff017 !important;
      }
    }
    &-addteams {
      width: 110px;
      padding: 0 0.9rem 0 0.2rem;
      font-size: 14px;
      color: #fff;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 0.5rem;
    }
    &-icon {
      margin-right: 10px;
    }
    &-addentry {
      width: 81px;
      padding: 0 0.9rem 0 0.2rem;
      font-size: 14px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin-bottom: 0.5rem;
    }
    &-button1 {
      width: 110px;
      padding: 0 0.5rem;
      font-size: 12px;
    }
    &-btnselect {
      background-color: #fff017;
      border: none;
      width: 110px;
      height: 38px;
      &:hover {
        color: #4e4b4b !important;
      }
    }
  }
  &-selections {
    width: 200px;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    &-self {
      display: flex;
      align-items: center;
    }
    &-icon {
      height: 24px;
      width: 24px;
      margin-right: 6px;
    }
    &-opponent {
      font-size: 10px;
      color: #b8b4b4;
      line-height: 10px;
      text-align: start;
      margin-left: 31px;
    }
  }
  .ant-table-cell-row-hover {
    background-color: #1b1b1b !important;
  }
  &-sharecont {
    display: block;
    width: 110px;
  }
  .sharebtn {
    margin-bottom: 1rem;
  }
}

.ant-pagination .ant-pagination-item a {
  color: #adadad;
}
.ant-pagination .ant-pagination-item-active:hover {
  background-color: #fff017;
  border-radius: 0;
  border: none;
  transform: skew(-5deg);
  & a:hover {
    color: #000;
  }
}
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  .anticon svg {
    color: #fff017;
  }
}
.ant-pagination
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: #fff017;
}
.ant-pagination .ant-pagination-prev button {
  .anticon svg {
    color: #fff017;
  }
}
.ant-pagination .ant-pagination-next button {
  color: #fff;
}
.ant-pagination
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: #fff;
}
.ant-pagination .ant-pagination-options {
  display: none;
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background-color: #1b1b1b;
}
.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-radius: 0;
}
.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:last-child
  > *:last-child {
  border-radius: 0;
}
.game-checkbox {
  display: grid;
  .ant-checkbox .ant-checkbox-inner {
    border-radius: 0;
    background-color: #fff;
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: #fff;
  }
  .ant-checkbox .ant-checkbox-inner:after {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
  }
}
.ant-dropdown-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-popover-placement-bottom .ant-popover-arrow {
  display: none;
}
.ant-popover .ant-popover-inner {
  border-radius: 0;
}

.ant-checkbox .ant-checkbox-inner {
  border-radius: 0;
  background-color: #000;
}

.ant-table-row-expand-icon-cell {
  display: none;
}
.game-table-row .ant-table-cell:last-child {
  text-align: center;
}
.ant-table-wrapper .ant-table-tbody > tr > td {
  background-color: #232323 !important;
}
.ant-empty-normal .ant-empty-description {
  color: #fff !important;
}
.result-icon {
  height: 16px;
  width: 16px;
  align-self: flex-start;
  margin-top: 5px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .ongoing-mobile {
    &-cont {
      border: 1px solid #fff017;
      box-shadow: 5px 5px 0px 0px #fff017;
      margin-bottom: 2rem;
      &-items {
        display: flex;
        padding: 1rem;
        border-bottom: 0.5px solid #d4d4d4;
        align-items: center;
        position: relative;
        &-title {
          width: 35%;
        }
      }
    }
    &-actions {
      &-button {
        width: 145px;
        padding: 0 0.5rem;
        font-size: 15px;
        color: #fff;
        margin-top: 0.3rem;
      }
      &-btnselect {
        background-color: #fff017;
        border: none;
        width: 145px;
        height: 32px;
        margin-top: 0.3rem;
        &:hover {
          color: #4e4b4b !important;
        }
      }
    }
  }
  .ongoing-table {
    &-private-tag {
      top: 0;
      left: inherit;
      right: 0;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 0;
    }
    &-selections {
      justify-content: flex-start;
      &-opponent {
        margin-left: 30px;
      }
    }
    &-sharecont {
      display: block;
      width: 145px;
    }
    .sharebtn {
      margin-bottom: 0.5rem;
    }
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .ongoing-mobile {
    &-cont {
      border: 1px solid #fff017;
      box-shadow: 5px 5px 0px 0px #fff017;
      margin-bottom: 2rem;
      &-items {
        display: flex;
        padding: 1rem;
        border-bottom: 0.5px solid #d4d4d4;
        align-items: center;
        &-title {
          width: 35%;
        }
      }
    }
  }
}
@media (min-width: 1024px) and (max-width: 1300px) {
  .ongoing-table {
    .ant-table-wrapper .ant-table {
      font-size: 12px;
      .ant-table-wrapper .ant-table-thead > tr > th {
        font-size: 14px;
      }
    }
    &-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 260px;
      margin: auto;
      &-cont {
        width: 55%;
        line-height: 22px;
      }
      &-title {
        color: #fff017;
        margin-bottom: 6px;
      }
      &-button {
        width: 110px;
        padding: 0 0.5rem;
        font-size: 12px;
      }
      &-btnselect {
        width: 110px;
        height: 38px;
        font-size: 12px;
      }
      &-btnview {
        width: 110px;
        padding: 0 0.5rem;
        font-size: 12px;
        height: 38px !important;
      }
    }
    &-selections {
      margin-bottom: 1rem;
      &-icon {
        height: 20px;
        width: 20px;
        margin-right: 6px;
      }
      &-opponent {
        margin-left: 27px;
      }
    }
    &-competitions {
      &-image {
        width: 20px;
        height: 20px;
      }
    }
  }
}
