.notifications {
  max-width: 980px;
  margin: auto;
  &-title {
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    margin-bottom: 2rem;
  }
  &-main {
    margin: 0 4rem;
    padding: 1rem 2rem 0.5rem 2rem;
  }
  &-cont {
    height: 450px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff017;
      width: 12px;
      &:hover {
        background-color: #fff017;
      }
    }
    &-item {
      display: flex;
      border-bottom: 0.5px solid #d4d4d4;
      padding: 1rem 0.5rem 1rem 0.5rem;
      &-image {
        height: 43px;
        width: 43px;
        margin-right: 1.5rem;
      }
      &-details {
        width: 88%;
        margin-right: 3rem;
        &-top {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1.2rem;
          &-title {
            font-size: 16px;
          }
          &-right {
            font-size: 12px;
            display: flex;
          }
        }
        &-bottom {
          font-size: 14px;
          line-height: 19px;
        }
      }
      &-delete {
        align-self: center;
        &-icon {
          height: 35px;
          width: 47px;
          border: 1px solid #fff017;
          display: flex;
          justify-content: center;
          align-items: center;
          transform: skew(-15deg);
          &-remove {
            transform: skew(15deg);
          }
        }
      }
    }
  }
  &-delete-all {
    display: flex;
    justify-content: end;
    margin-top: 0.5rem;
  }
  &-delete-button {
    color: #fff017;
    &:not(:disabled):hover {
      color: #d4d4d4;
    }
  }
  &-no-noti {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #fff017;
    height: 100%;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .notifications {
    &-title {
      font-size: 30px;
      line-height: 46px;
    }
    &-main {
      padding: 1rem 0.5rem;
      margin: 0 0.5rem;
    }
    &-cont {
      height: 600px;
      &-item {
        display: grid;
        border-bottom: 0.5px solid #d4d4d4;
        padding: 1.2rem 0.5rem 1rem 0.5rem;
        &-cont {
          display: flex;
        }
        &-image {
          height: 28px;
          width: 32px;
          margin-right: 1rem;
        }
        &-details {
          width: 100%;
          margin-right: 0rem;
          &-top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.4rem;
            &-title {
              font-size: 18px;
            }
            &-right {
              font-size: 14px;
              display: flex;
              align-items: center;
            }
          }
          &-bottom {
            font-size: 16px;
            line-height: 22px;
          }
        }
        &-delete {
          display: flex;
          justify-content: space-between;
          margin-top: 1.5rem;
          margin-bottom: 1rem;
          &-icon {
            height: 37px;
            width: 39px;
          }
        }
      }
    }
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .notifications {
    &-title {
      font-size: 30px;
      line-height: 46px;
    }
    &-cont {
      margin: 0 0.5rem;
      padding: 1rem 0.5rem;
      &-item {
        display: grid;
        border-bottom: 0.5px solid #d4d4d4;
        padding: 1.2rem 0.5rem 1rem 0.5rem;
        &-cont {
          display: flex;
        }
        &-image {
          height: 28px;
          width: 32px;
          margin-right: 1rem;
        }
        &-details {
          width: 100%;
          margin-right: 0rem;
          &-top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.4rem;
            &-title {
              font-size: 18px;
            }
            &-right {
              font-size: 14px;
              display: flex;
              align-items: center;
            }
          }
          &-bottom {
            font-size: 16px;
            line-height: 22px;
          }
        }
        &-delete {
          display: flex;
          justify-content: space-between;
          margin-top: 1.5rem;
          &-icon {
            height: 37px;
            width: 39px;
          }
        }
      }
    }
  }
}
