.mainHeadingActivity {
  display: flex;
  justify-content: center;
  font-family: "Cairo ExtraBold";
  font-size: 40px;
  font-weight: 900;
  line-height: 44px;
  text-align: center;
  margin-bottom: 1.5rem;
}
.mainContainerActivity {
  display: flex;
  flex-direction: column;
  max-width: 1240px;
  margin: auto;
  padding: 0 5rem;
}
.historyHeading {
  font-family: "Cairo Bold";
  font-size: 24px;
  line-height: 46px;
}
.start-input-field {
  background-color: transparent;
  border: none;
  transform: skew(20deg);
  height: 40px;
  width: 140px;
  padding: 0 1rem;
  font-size: 14px;
  font-family: "Cairo Normal";
  border-radius: 0;
  &:focus {
    outline: none;
  }
  .ant-picker-suffix {
    color: #fff017;
  }
}
.activity-error {
  justify-content: center;
}
.timeHeadingFrom {
  font-family: "Cairo Normal";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.025em;
  text-align: left;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-top: 10px;
}
.fieldsCont {
  display: flex;
  margin-left: 0.8rem;
}
.timeHeading {
  font-family: "Cairo Normal";
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.025em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 15px;
}
.subMainActivity {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.insights-standings-dropdownss {
  display: flex;
  width: 56%;

  justify-content: center;
  margin-top: 1rem;
}
.styleTable {
  width: 100%;
  display: flex;
  justify-content: center;
}
.startTime {
  height: 60px;
  &-cont {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.endTime {
  padding-right: 10px;
  &-cont {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.tableMainContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}
.searchContainer {
  transform: skew(-20deg);
  width: 51px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff017;
  border: none;
  margin-left: 0.5rem;
}
.searchIcon {
  width: 19px;
  height: 19px;
  top: 1266.384765625px;
  left: 1238.70703125px;
}
.error {
  color: red;
}
.extraBottomMargin {
  margin-bottom: 8rem;
  margin-top: 1rem;
}
.activity {
  &-title {
    font-size: 24px;
    margin-bottom: 1rem;
  }
  &-buttons {
    padding: 0 5rem;
    display: flex;
    justify-content: center;
  }
  &-btngamehistory {
    background-color: #fff017 !important;
    border: none;
    width: 200px;
    margin-right: 0.5rem;
    color: #000;
    &:hover {
      color: #000 !important;
    }
  }
  &-btncurrentactive {
    background-color: transparent;
    border: 1px solid #fff017;
    width: 200px;
    margin-right: 0.5rem;
    color: #fff017;
    &:hover {
      border-color: #fff017 !important;
      color: #fff017 !important;
    }
  }
  &-btnpayment {
    margin-right: 0.5rem;
    width: 200px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .mainContainerActivity {
    padding: 0rem 1rem;
  }
  .mainHeadingActivity {
    margin-bottom: 0.5rem;
    font-size: 32px;
  }
  .activity {
    &-btngamehistory {
      width: 140px;
      padding: 0;
      margin-right: 0.5rem;
    }
    &-btncurrentactive {
      width: 140px;
      padding: 0;
    }
    &-btnpayment {
      margin-right: 0.5rem;
      width: 140px;
      padding: 0;
    }
    &-buttons {
      padding: 0 2rem;
      margin-bottom: 0.5rem;
      margin-left: 5px;
    }
    &-mobile {
      &-buttons {
        display: flex;
      }
    }
  }
  .historyHeading {
    font-size: 24px;
    line-height: 28px;
    margin-top: 1rem;
  }
  .leagueMobile {
    font-size: 16px;
    margin-top: 0.5rem;
  }
  .mainContainerLeague {
    height: 560px;
    width: 100%;
    overflow-y: scroll;
    margin-bottom: 1rem;
    margin-top: 1rem;
    scrollbar-color: #fff017 transparent;
    scrollbar-width: thin !important;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff017;
      &:hover {
        background-color: #fff017;
        scrollbar-width: thin !important;
      }
    }
  }
  .leagueTable {
    width: 100%;
    height: 240px;
    border: 1px solid #fff017;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #161616;
    flex-direction: column;
    padding: 1rem;
  }
  .rangedates {
    background-color: black;
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 16px;
    line-height: 23px;
    color: #6c6c6c;
  }
  .dateJoin {
    background-color: black;
    display: flex;
    align-items: center;
    width: 100%;
    &-button {
      width: 140px;
      padding: 0 0.5rem;
      font-size: 12px;
      color: #fff;
      height: 38px !important;
      &:hover {
        color: #fff017 !important;
        border-color: #fff017 !important;
      }
    }
  }
  .dateMobileApp {
    font-size: 14px;
    width: 50%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .dateText {
    font-size: 14px;
  }
  .dateInterval {
    font-size: 14px;
    line-height: 23px;
    color: #6c6c6c;
  }
  .withdrawSubMain {
    width: 100%;
    padding: 1rem;
  }
  .withdrawHisotyMain {
    width: 100%;
    height: 340px;
    top: 1541.671875px;
    left: 15px;
    border: 0.5px;
    border: 1px solid #fff017;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: #161616;
    &-nodata {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }
  }
  .start-input-field {
    width: 100%;
  }
  .startTime {
    width: 50%;
  }
  .insights-standings-dropdownss {
    width: 100%;
    flex-wrap: wrap;
  }
  .fieldsCont {
    margin-left: 0;
    display: block;
    width: 96%;
  }
  .searchContainer {
    width: 100%;
    margin-left: 0;
  }
  .outerSearch {
    width: 92%;
    margin-top: 2rem;
  }
  .timeHeadingFrom {
    padding-top: 0;
    margin-bottom: 4px;
  }
  .activity-error {
    justify-content: flex-start;
  }
}
