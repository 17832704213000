.banner-subtext {
  font-size: 30px !important;
  font-family: "Cairo normal";
  display: flex;

  .time-unit {
    display: inline-block;
    width: 37px;
    text-align: center; // Center text within the span
  }
  span {
    color: #fff017 !important;
    font-family: "Cairo normal";
  }
}

@media (max-width: 456px) {
  .banner-subtext {
    display: block;
  }
}
