.expanded-details {
  padding: 2rem 4rem;
  border: 0.5px solid #8e8e8e;
  &-top {
    display: flex;
    justify-content: space-between;
    width: 78%;
    &-left {
      display: flex;
      &-image {
        margin-right: 10px;
      }
      &-text {
        display: grid;
      }
    }
    &-right {
      width: 50%;
      &-radio {
        .ant-radio-wrapper {
          color: white;
        }
      }
    }
  }
  &-bottom {
    display: flex;
    &-fixtures {
      width: 48%;
      border-right: 1px solid #aaaaaa;
      &-title {
        padding: 2rem 0 1rem 0;
      }
      &-columns {
        display: flex;
        &-one {
          &-div {
            display: flex;
            padding-bottom: 6px;
            .fixtures-list-title {
              width: 70%;
              margin-right: 12px;
            }
            .fixtures-list-image {
              width: 26px;
              height: 26px;
            }
          }
        }
        &-two {
          &-div {
            display: flex;
            padding-bottom: 6px;
            .fixtures-list-title {
              padding-left: 12px;
            }
            .fixtures-list-image {
              width: 26px;
              height: 26px;
            }
          }
        }
      }
    }
    &-selections {
      width: 48%;
      padding: 0 2rem;
      &-title {
        padding: 2rem 0 1.5rem 0;
      }
      .selections-teams-list {
        &-items {
          display: flex;
          align-items: center;
          margin-bottom: 6px;
          &-name {
            padding-left: 8px;
          }
        }
      }
      &-button {
        width: 96%;
        font-size: 12px;
        height: 35px;
      }
    }
    &-participants {
      width: 28%;
      display: grid;
      padding-left: 2rem;
      &-title {
        padding-top: 2rem;
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 1rem;
      }
      &-contt {
        &-number {
          height: 56px;
          width: 41px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 34px;
          margin-right: 8px;
        }
      }
    }
    &-vs {
      font-size: 17px;
      color: #fff017;
      height: 34.5px;
      padding: 0 12px;
    }
  }
  &-dropdown {
    color: #fff;
    scrollbar-color: red;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff017;
      width: 12px;
      &:hover {
        background-color: #fff017 !important;
      }
    }
    &:not(.ant-select-customize-input) .ant-select-selector {
      height: 48px;
      padding: 9px 11px;
      color: #fff;
      background-color: transparent;
      border: none;
    }
    &-icon {
      height: 32px;
      width: 32px;
      margin-right: 8px;
    }
    &-popup {
      scrollbar-color: red;
      .ant-select-item {
        border-bottom: 1px solid #aaaaaa;
        margin: 0 8px;
        padding: 12px;
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .expanded-details {
    padding: 2rem 1rem;
    &-bottom {
      display: grid;
      &-vs {
        padding: 0 24px;
        height: 33px;
      }
      &-fixtures {
        width: 100%;
        border-right: none;
        padding: 0 1rem;
      }
      &-selections {
        width: 100%;
        border-right: none;
        padding: 0 1rem;
      }
      &-participants {
        width: 100%;
        padding-left: 1rem;
      }
    }
    &-dropdown {
      width: 100% !important;
    }
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .expanded-details {
    padding: 2rem 1rem;
    &-bottom {
      display: grid;
      &-vs {
        padding: 0 36px;
        height: 33px;
      }
      &-fixtures {
        width: 100%;
        border-right: none;
        padding: 0 1rem;
      }
      &-selections {
        width: 100%;
        border-right: none;
        padding: 0 1rem;
      }
      &-participants {
        width: 100%;
        padding-left: 1rem;
      }
    }
    &-dropdown {
      width: 100% !important;
    }
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .expanded-details {
    &-bottom {
      &-vs {
        height: 32px;
      }
    }
  }
}
.iconLeague
{
  height: 30px !important;
  width: 35px;
}