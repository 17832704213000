@font-face {
  font-family: "Cairo Bold";
  font-display: swap;
  src: url("../../../../assets/fonts/Cairo-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Cairo Normal";
  font-display: swap;
  src: url("../../../../assets/fonts/Cairo-Regular.ttf");
  font-weight: 400;
}
.self-exclusion {
  margin-top: 0.5rem;
  color: #fff;
  &-title {
    font-size: 24px;
  }
  &-description {
    font-size: 14px;
    line-height: 18px;
    &-link {
      color: #fff;
      &:hover {
        color: #fff017;
      }
    }
  }
  &-select {
    &-label {
      font-size: 16px;
      margin-bottom: 0.6rem;
    }
  }
  &-dropdown-filter {
    width: 100%;
    &:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #1b1b1b;
      height: 48px;
      padding: 8px 22px;
      border-radius: 0;
      color: #fff;
      border: none;
    }
    .ant-select-selector .ant-select-selection-item {
      text-align: left;
      font-size: 14px;
      font-family: "Cairo Medium";
      color: #fff;

    }
    &-popupmenu {
      width: 226px !important;
      min-width: 226px !important;
      z-index: 1111;
      .ant-select-item {
        color: #fff;
        border-bottom: 1px solid #aaaaaa;
        border-radius: 0;
        padding: 1rem 0.5rem;
        margin: 0 0.5rem;
        font-family: "Cairo Normal";
      }
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: transparent;
        color: #fff;
      }
    }
  }
  &-padding{
    padding-bottom: 1.2rem;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .self-exclusion {
    &-title {
      font-size: 22px;
      margin-top: 0;
    }
    &-description {
      line-height: 18px;
    }
  }
}
