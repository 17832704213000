@font-face {
  font-family: "Cairo Bold";
  font-display: swap;
  src: url("../../../assets/fonts/Cairo-Bold.ttf");
  font-weight: 700;
}
.contactus-form-modal {
  &-inner {
    width: 58% !important;
    .ant-modal-content {
      border-radius: 0;
      border: 1px solid #fff017;
      background: rgba(0, 0, 0, 0.2);
      box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(11.6334px);
    }
    .ant-modal-header {
      background-color: transparent;
      .ant-modal-title {
        color: #fff;
        font-size: 40px;
        text-align: center;
        font-family: "Cairo Bold";
        margin-bottom: 1rem;
      }
    }
  }
}
.contactus-form {
  width: 85%;
  margin: auto;
  &-fieldscont {
    display: grid;
    align-content: baseline;
    height: 95px;
  }
  &-textarea {
    display: grid;
    align-content: baseline;
    height: 204px;
    &-field {
      background-color: #1b1b1b;
      border: none;
      height: 150px;
      padding: 1.8rem 1rem;
      font-size: 16px;
      font-family: "Cairo Normal";
      color: #fff;
      &:focus {
        border: 1px solid #fff017;
        outline: none;
      }
      .ant-picker-suffix {
        color: #fff017;
      }
    }
  }
  &-field {
    background-color: #1b1b1b;
    border: none;
    height: 48px;
    padding: 0 1rem;
    font-size: 16px;
    font-family: "Cairo Normal";
    color: #fff;
    &:focus {
      border: 1px solid #fff017;
      outline: none;
    }
    .ant-picker-suffix {
      color: #fff017;
    }
  }
  &-button {
    transform: skew(-20deg);
    width: 35%;
    height: 45px;
    border: none;
    color: #000;
    font-family: "Cairo Bold";
    font-size: 18px;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    &-text {
      transform: skew(20deg);
    }
  }
  &-tickicon{
    svg{
      color: #fff017 !important;
      width: 70px;
      height: 70px;
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .contactus-form-modal {
    &-inner {
      width: 100% !important;
      max-width: 94%;
    }
  }
  .contactus-form {
    width: 100%;
    &-button {
      width: 72%;
    }
  }
}
