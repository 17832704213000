.deposit {
  &-text {
    color: white;
    font-size: 30px;
    margin-bottom: 0.5rem;
  }
  &-edit-icon {
    position: absolute;
    top: 45px;
    left: 95%;
  }
  &-edit-icon1 {
    position: absolute;
    top: 53px;
    left: 95%;
  }
  &-input-field {
    background-color: #1b1b1b;
    border: none;
    height: 51px;
    padding: 0 1rem;
    font-size: 16px;
    color: #fff;
    padding-right: 2.5rem;
    width: 100%;
  }
  &-field-logo {
    width: 26px;
    height: 20px;
    margin-right: 6px;
  }
  &-truelayer-cont {
    background-color: #1b1b1b;
    border: none;
    height: 51px;
    padding: 0 1rem;
    font-size: 13px;
    color: #fff;
    padding-right: 2.5rem;
    width: 100%;
    display: flex;
    // justify-content: center;
    align-items: center;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .contact-details {
    margin-top: 1rem;
    &-text {
      font-size: 24px;
      margin-bottom: 1rem;
    }
    &-edit-icon {
      top: 43px;
      left: 90%;
    }
    &-edit-icon1 {
      top: 48px;
      left: 90%;
    }
    &-input-field {
      padding-right: 2.5rem;
    }
  }
}
