.account-closure {
  color: #fff;
  margin-bottom: 0.5rem;
  &-title {
    font-size: 24px;
  }
  &-description {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 1.5rem;
  }
  &-select {
    &-label {
      font-size: 16px;
      margin-bottom: 0.6rem;
    }
  }
  &-dropdown-filter {
    width: 100%;
    &:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #1b1b1b;
      height: 51px;
      padding: 8px 22px;
      border-radius: 0;
      color: #fff;
      border: none;
    }
    .ant-select-selector .ant-select-selection-item {
      text-align: left;
      font-size: 14px;
      font-family: "Cairo Medium";
      color: #fff;
    }
    &-popupmenu {
      width: 340px !important;
      min-width: 340px !important;
      z-index: 1111;
      .ant-select-item {
        color: #fff;
        border-bottom: 1px solid #aaaaaa;
        border-radius: 0;
        padding: 1rem 0.5rem;
        margin: 0 0.5rem;
        font-family: "Cairo Normal";
      }
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: transparent;
        color: #fff;
      }
    }
  }
  &-padding {
    padding-bottom: 1.2rem;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .account-closure {
    &-title {
      font-size: 24px;
    }
    &-description {
      line-height: 20px;
    }
    &-padding {
      padding-bottom: 0.6rem;
    }
  }
}
