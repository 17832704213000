.expanded-details {
  padding: 2rem 4rem;
  border: 0.5px solid #8e8e8e;
  &-top {
    display: flex;
    justify-content: space-between;
    width: 78%;
    &-left {
      display: flex;
      &-image {
        margin-right: 10px;
        height: 47px;
        width: 47px;
      }
      &-text {
        display: grid;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      width: 46%;
      justify-content: space-between;
      &-radio {
        .ant-radio-wrapper {
          color: white;
          .ant-radio-inner {
            border-color: #fff017;
            background-color: #000;
          }
          .ant-radio-inner::after {
            background-color: #fff017;
            width: 25px;
            height: 25px;
            margin-block-start: -12.5px;
            margin-inline-start: -12.5px;
          }
        }
      }
    }
  }
  &-bottom {
    display: flex;
    &-fixtures {
      scrollbar-color: #fff017 transparent;
      scrollbar-width: thin !important;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #fff017;
        &:hover {
          background-color: #fff017;
          scrollbar-width: thin !important;
        }
      }
      width: 48%;
      border-right: 1px solid #aaaaaa;
      height: 360px;
      overflow-y: scroll;
      &-title {
        padding: 2rem 0 1rem 0;
      }
      &-columns {
        display: flex;
        &-one {
          &-div {
            display: flex;
            padding-bottom: 6px;
            .fixtures-list-title {
              width: 70%;
              margin-right: 12px;
            }
            .fixtures-list-image {
              width: 26px;
              height: 26px;
            }
          }
        }
        &-two {
          &-div {
            display: flex;
            padding-bottom: 6px;
            .fixtures-list-title {
              padding-left: 12px;
            }
            .fixtures-list-image {
              width: 26px;
              height: 26px;
            }
          }
        }
      }
    }
    &-selections {
      width: 48%;
      padding: 0 2rem;
      text-align: left;
      &-title {
        padding: 2rem 0 1.5rem 0;
      }
      .selections-teams-list {
        &-items {
          display: flex;
          align-items: center;
          margin-bottom: 6px;
          &-name {
            padding-left: 8px;
          }
        }
      }
      &-button {
        width: 96%;
        font-size: 12px;
        height: 35px;
      }
    }
    &-participants {
      width: 28%;
      display: grid;
      padding-left: 2rem;
      &-title {
        padding-top: 2rem;
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 1rem;
      }
      &-contt {
        &-number {
          height: 56px;
          width: 41px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 34px;
          margin-right: 8px;
        }
      }
    }
    &-vs {
      font-size: 17px;
      color: #fff017;
      height: 34.5px;
      padding: 0 12px;
    }
    &-btnselections {
      color: #fff;
      width: 50%;
      padding: 0;
      font-size: 12px;
    }
  }
  &-dropdown {
    color: #fff;
    width: 100%;
    margin-bottom: 0.5rem;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff017;
      width: 12px;
      &:hover {
        background-color: #fff017 !important;
      }
    }
    &:not(.ant-select-customize-input) .ant-select-selector {
      height: 48px;
      padding: 9px 11px;
      color: #fff;
      background-color: transparent;
      border: none;
      .ant-select-selection-item {
        color: #fff;
      }
    }
    &-icon {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
    &-popup {
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #fff017;
        width: 12px;
      }
      .ant-select-item {
        border-bottom: 1px solid #aaaaaa;
        margin: 0 8px;
        padding: 12px;
      }
    }
    &-main {
      display: flex;
      align-items: center;
      .iconLeague {
        height: 24px !important;
        width: 24px !important;
        margin-right: 6px;
        display: flex;
      }
    }
  }
  &-cont {
    &-date {
      font-size: 14px;
      text-align: center;
    }
    &-teams {
      justify-content: center;
      font-size: 12px;
      margin-top: 1rem;
      align-items: center;
      display: flex;
      &-one {
        width: 35%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-name {
          width: 90%;
          text-align: end;
          padding: 0 1rem;
        }
        &-image {
          height: 24px;
          width: 24px;
        }
      }
      &-two {
        width: 35%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-name {
          width: 90%;
          text-align: start;
          padding: 0 1rem;
        }
        &-image {
          height: 24px;
          width: 24px;
        }
      }
      &-time {
        width: 20%;
        text-align: center;
      }
    }
    &-line {
      height: 1px;
      width: 100%;
      background-color: #d4d4d4;
      margin: 1rem 0;
    }
    &-error {
      font-size: 36px;
      width: 70%;
      margin: auto;
      color: #fff017;
    }
  }
  &-turbotag {
    background-color: #fff;
    position: absolute;
    top: 16px;
    left: 16px;
    color: #000;
    border-bottom-right-radius: 12px;
    font-size: 12px;
    padding: 0px 12px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .expanded-details {
    padding: 2rem 1rem;
    position: relative;
    &-turbotag {
      top: 0px;
      left: 0px;
    }
    &-top {
      width: 100% !important;
      display: block !important;
      &-right {
        width: 100% !important;
        font-size: 12px;
        &-radio {
          .ant-radio-wrapper {
            color: white;
            margin-right: 4px;
            font-size: 12px;
            .ant-radio-inner {
              border-color: #fff017;
              background-color: #000;
            }
            .ant-radio-inner::after {
              background-color: #fff017;
              width: 25px;
              height: 25px;
              margin-block-start: -12.5px;
              margin-inline-start: -12.5px;
            }
          }
        }
      }
    }
    &-bottom {
      display: grid;
      &-vs {
        padding: 0 24px;
        height: 33px;
      }
      &-fixtures {
        width: 100%;
        border-right: none;
        padding: 0 1rem;
      }
      &-selections {
        width: 100%;
        border-right: none;
        padding: 0 1rem;
      }
      &-participants {
        width: 100%;
        padding-left: 1rem;
      }
      &-btnselections {
        width: 150px;
      }
    }
    &-dropdown {
      width: 100% !important;
    }
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .expanded-details {
    padding: 2rem 1rem;
    &-bottom {
      display: grid;
      &-vs {
        padding: 0 36px;
        height: 33px;
      }
      &-fixtures {
        width: 100%;
        border-right: none;
        padding: 0 1rem;
      }
      &-selections {
        width: 100%;
        border-right: none;
        padding: 0 1rem;
      }
      &-participants {
        width: 100%;
        padding-left: 1rem;
      }
    }
    &-dropdown {
      width: 100% !important;
    }
    &-cont {
      &-error {
        font-size: 18px;
        width: 90%;
      }
      &-teams {
        justify-content: center;
        font-size: 12px;
        margin-top: 1rem;
        align-items: center;
        display: flex;
        &-one {
          width: 40%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-name {
            width: 90%;
            text-align: end;
            padding: 0 0.3rem;
          }
          &-image {
            height: 24px;
            width: 24px;
          }
        }
        &-two {
          width: 40%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &-name {
            width: 90%;
            text-align: start;
            padding: 0 0.3rem;
          }
          &-image {
            height: 24px;
            width: 24px;
          }
        }
        &-time {
          width: 20%;
          text-align: center;
        }
      }
    }
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .expanded-details {
    &-bottom {
      &-vs {
        height: 32px;
      }
    }
  }
}
.iconLeague {
  height: 30px !important;
  width: 35px;
}
