.simplefilledbutton {
  background-color: #fff; /* Keep only one */
  border-radius: 0;
}
.discount-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.strike-through {
  color: black !important;
  font-family: "Cairo Bold" !important;
  font-size: 15px;
  text-decoration: underline !important;
  text-underline-offset: -40%; /* This property may not be widely supported */
  text-decoration-skip-ink: none;
  padding: 0 0.2em; 
  letter-spacing: -1px;
}

.simplefilledbutton:hover {
  color: #3498db !important;
}
.font-green{
  color: green !important;
  font-weight: bold;
}
.simplefilledbutton:hover {
  color: #3498db !important;
}

.simplefilledbutton:hover span {
  color: #3498db !important;
}

