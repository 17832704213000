/* Styles for the toast container */
@media only screen and (min-width: 480px) {
.toast-container {
  background: transparent !important;
  box-shadow: none !important;
  width: auto !important;
  min-width: 300px !important;
}

/* Styles for the toast body */
.toast-body {
  background: rgb(241, 198, 198) !important;
  color: #000 !important;
  border-radius: 5px !important;
  padding: 10px !important;
  position: relative !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  min-width: 300px !important;
}

.toast-success {
  background-color: lightgreen !important;
  border-left: 5px solid #155724 !important;
  color: #155724;
}

.toast-error {
  background-color: #f8d7da !important;
  color: #721c24;
  border-left: 5px solid #c93026 !important;
}

.toast-warning {
  background-color: lightyellow !important;
  border-left: 5px solid yellow !important;
  color: #856404;
}

.Toastify__toast-container {
  background: transparent !important;
  min-width: 300px !important;
}

.Toastify__toast {
  background: transparent !important;
  box-shadow: none !important;
  min-width: 500px !important;
}

.Toastify__toast-body {
  background: transparent !important;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 45% !important;
  transform: translateX(-50%);
}
}
@media only screen and (max-width: 480px) {
 /* General Styles for the Toast Container */
 .Toastify__toast-theme--colored.Toastify__toast--default {
  background: transparent !important;
  color: var(--toastify-text-color-light);
}
.toast-container,
.Toastify__toast-container {
  background: transparent !important;
  box-shadow: none !important;
  width: auto !important;
  min-width: 300px !important; /* Minimum width for larger screens */
  max-width: 90% !important; /* Allow the container to take up to 90% of the screen width */
  padding: 0 10px; /* Small padding to avoid touching screen edges */
  box-sizing: border-box;
  left: 50% !important; /* Center the toast container */
  transform: translateX(-50%) !important; /* Ensure proper centering */
}

/* Styles for the Toast Body */
.toast-body,
.Toastify__toast-body {
  background: rgb(241, 198, 198) !important;
  color: #000 !important;
  border-radius: 5px !important;
  padding: 0px !important;
  position: relative !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100% !important; /* Allow body to adjust to container width */
  min-width: 250px; /* Minimum width for smaller screens */
  max-width: 480px; /* Limit maximum width on larger screens */
}

/* Styles for Specific Toast Types */
.toast-success {
  background-color: lightgreen !important;
  border-left: 5px solid #155724 !important;
  color: #155724;
}

.toast-error {
  background-color: #f8d7da !important;
  color: #721c24;
  border-left: 5px solid #c93026 !important;
}

.toast-warning {
  background-color: lightyellow !important;
  border-left: 5px solid yellow !important;
  color: #856404;
}

/* Responsive Styles */
@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 90% !important; /* Adjust to 90% of screen width */
    min-width: 0 !important; /* Remove minimum width restriction */
    left: 50% !important;
    transform: translateX(-50%); /* Center the toast horizontally */
  }

  .Toastify__toast {
    width: 100% !important; /* Take full width of the container */
    max-width: 480px !important; /* Set maximum width to 480px */
  }

  .Toastify__toast-container--top-left,
  .Toastify__toast-container--top-center,
  .Toastify__toast-container--top-right {
    top: 1em !important;
    width: 90% !important; /* Adjust to 90% of screen width */
    left: 50%;
    transform: translateX(-50%) !important;
  }
}

}