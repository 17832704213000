@font-face {
  font-family: "Cairo Bold";
  font-display: swap;
  src: url("../../../../assets/fonts/Cairo-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Cairo Normal";
  font-display: swap;
  src: url("../../../../assets/fonts/Cairo-Regular.ttf");
  font-weight: 400;
}
.my-preferences {
  margin-top: 0.5rem;
  &-title {
    color: #fff;
    font-size: 24px;
  }
  &-dropdowns {
    display: flex;
    justify-content: space-between;
    &-language {
      color: #fff;
      width: 48%;
      .ui.selection.dropdown {
        background-color: transparent;
        color: #fff;
        display: flex;
        justify-content: space-between;
      }
      .ui.fluid.dropdown > .dropdown.icon {
        top: 8px !important;
      }
    }
    &-timezones {
      width: 48%;
      color: #fff;
      .ui.selection.dropdown {
        background-color: transparent;
        color: #fff;
        display: flex;
        justify-content: space-between;
      }
      .ui.fluid.dropdown > .dropdown.icon {
        top: 8px !important;
      }
    }
  }
  &-bottom {
    font-size: 16px;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      margin-bottom: 1rem;
    }
    &-line {
      height: 1px;
      width: 75%;
      background-color: #5a5a5a;
    }
    &-external {
      margin-top: 2rem;
      .ant-collapse-item:last-child .ant-collapse-header {
        color: #fff;
      }
      .ant-collapse-content {
        color: #fff;
      }
    }
    &-expand-div {
      width: 19px;
      height: 19px;
      background-color: #333000;
      border-radius: 222px;
      display: flex;
      justify-content: center;
      align-items: center;
      &-icon {
        & svg {
          width: 15px;
          height: 7px;
          color: #fff017;
        }
      }
    }
    &-content {
      display: flex;
      justify-content: space-between;
      &-left {
        width: 35%;
      }
      &-right {
        width: 55%;
        margin-top: 4.5rem;
      }
    }
    &-div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3rem;
      color: #fff;
    }
  }
  &-buttons {
    border: 1px solid #fff017;
    width: 104px;
    height: 29px;
    display: flex;
    transform: skew(-12deg);
    font-size: 14px;
    &-on {
      color: #000;
      background-color: #fff017;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Cairo Bold";
      cursor: pointer;
    }
    &-off {
      color: #fff017;
      background-color: #000;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Cairo Normal";
      cursor: pointer;
    }
  }
  &-fields-cont {
    height: 80px;
    &-items {
      display: flex;
      align-items: center;
    }
  }
  &-field-label {
    width: 20%;
    color: #fff;
  }
  &-input-field {
    width: 80%;
    height: 48px;
    background-color: #1b1b1b;
    border: none;
    padding: 0 1rem;
    font-size: 16px;
    font-family: "Cairo Normal";
    color: #fff;
    padding-right: 1.5rem;
    &:focus {
      border: 1px solid #fff017;
      outline: none;
    }
  }
  &-phone-field {
    width: 80%;
    height: 48px;
    background-color: #1b1b1b;
    border: none;
    padding: 0 1rem;
    font-size: 16px;
    font-family: "Cairo Normal";
    color: #fff;
    padding-right: 1.5rem;
  }
  &-edit-icon {
    position: absolute;
    top: 9px;
    left: 92%;
  }
  .error-padding {
    margin-left: 20%;
  }
  .extra-padding {
    padding-right: 3rem;
  }
}

.PhoneInput--focus {
  border: 1px solid #fff017 !important;
}
@media (min-width: 320px) and (max-width: 480px) {
  .my-preferences {
    &-dropdowns {
      flex-wrap: wrap;
      &-language {
        width: 100%;
      }
      &-timezones {
        width: 100%;
      }
    }
    &-bottom {
      &-external {
        margin-top: 0.5rem;
        .ant-collapse-item:last-child .ant-collapse-header {
          color: #fff;
          padding: 1rem 0.6rem;
        }
        .ant-collapse-content > .ant-collapse-content-box {
          padding: 12px 4px;
        }
      }
      &-content {
        flex-direction: column;
        width: 100%;
        &-left {
          width: 98%;
        }
        &-right {
          width: 99%;
        }
      }
      &-div {
        margin-bottom: 1rem;
      }
    }
    &-input-field {
      font-size: 14px;
      padding-right: 2.2rem;
    }
  }
}
