.home-news {
  padding: 4rem 8rem;
  margin: 6rem 0 0 0;
  display: grid;
  justify-content: end;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 250px;
  &-bg {
    background-image: url("../../../assets/images/strategy-bg.png");
  }
  &-bg-mobile {
    background-image: url("../../../assets/images/strategy-bgmobile.png");
  }
  &-title {
    font-size: 16px;
    letter-spacing: 0.31em;
    line-height: 20px;
    // margin-bottom: 1rem;
  }
  &-prematch {
    font-size: 32px;
    line-height: 37px;
    margin-bottom: 1.5rem;
  }
  &-button {
    margin-top: 1rem;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .home-news {
    padding: 4rem 0 6rem 0;
    justify-content: center;
    margin-bottom: 0;
    &-title {
      font-size: 14px;
      margin-bottom: 0;
    }
    &-prematch {
      font-size: 30px;
      line-height: 33px;
      margin-bottom: 0;
    }
    &-button {
      margin-top: 1rem;
    }
  }
  .filled-button {
    height: 40px;
  }
  .padding-x6 {
    padding: 0 3rem !important;
  }
}
