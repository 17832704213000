.verification {
  color: #fff;
  margin-bottom: 0.5rem;
  &-title {
    font-size: 24px;
    margin-top: 0.5rem;
  }
  &-account {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 1.5rem;
  }
  &-details {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 2rem;
  }
  &-image {
    width: 450px;
    height: 180px;
    margin: 3rem auto;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .verification {
    &-title {
      font-size: 24px;
    }
    &-image {
      margin: 2rem auto;
      width: 100%;
      height: 140px;
    }
  }
}
