@font-face {
  font-family: "Cairo Medium";
  font-display: swap;
  src: url("./assets/fonts/Cairo-Medium.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Cairo ExtraBold";
  font-display: swap;
  src: url("./assets/fonts/Cairo-ExtraBold.ttf");
  font-weight: 900;
}
@font-face {
  font-family: "Cairo Bold";
  font-display: swap;
  src: url("./assets/fonts/Cairo-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Cairo Normal";
  font-display: swap;
  src: url("./assets/fonts/Cairo-Regular.ttf");
  font-weight: 400;
}

/* font families */
.font-cairo-normal {
  font-family: "Cairo Normal";
}
.font-cairo-medium {
  font-family: "Cairo Medium";
}
.font-cairo-bold {
  font-family: "Cairo Bold";
}
.font-cairo-extrabold {
  font-family: "Cairo ExtraBold";
}
/* font sizes */
.font12 {
  font-size: 12px;
}
.font14 {
  font-size: 14px;
}
.font16 {
  font-size: 16px;
}
.font18 {
  font-size: 18px;
}
.font20 {
  font-size: 20px;
}
.font22 {
  font-size: 22px;
}
.font24 {
  font-size: 24px;
}
.font30 {
  font-size: 30px;
}
.font55 {
  font-size: 55px;
}
.font72 {
  font-size: 72px;
}
/* background colors */
.black-background {
  background-color: #000;
}
.lightblack-background {
  background-color: #1b1b1b;
}
.lightgrey-background {
  background-color: #d9d9d9;
}
.transparent-background {
  background-color: transparent;
}
.primary-background {
  background-color: #fff017;
}

/* colors */
.white-color {
  color: #ffffff;
}
.grey-color {
  color: #7b7b7b;
}
.black-color {
  color: #000;
}
.primary-color {
  color: #fff017;
}

/* link tag styles */
.link-style {
  text-decoration: none;
}
.link-style:hover {
  color: #fff017;
}
.link-style-underline {
  text-decoration: underline;
}
.link-style-underline:hover {
  color: #fff017;
}

/* positions */
.position-relative {
  position: relative;
}

/* border radius */
.border-50 {
  border-radius: 50%;
}

/* paddings */
.main-padding {
  padding: 0 5rem;
}
.padding-bottom1 {
  padding-bottom: 1rem;
}
.padding-bottom2 {
  padding-bottom: 2rem;
}
.padding-bottom8 {
  padding-bottom: 8rem;
}
.padding-top4 {
  padding-top: 4rem;
}
.padding-top2 {
  padding-top: 2rem;
}
.padding-x6 {
  padding: 0 6rem;
}
.padding-x5 {
  padding: 0 5rem;
}
.padding-x8 {
  padding: 0 8rem;
}
.padding-y1 {
  padding: 1rem 0;
}
.padding-x1 {
  padding: 0 1rem;
}

/* margins */
.error-icon-margin {
  margin-right: 0.5rem;
}

/* text alignments */
.text-center {
  text-align: center;
}
.text-start {
  text-align: start;
}
.text-end {
  text-align: end;
}

/* Line heights */
.line-height-34 {
  line-height: 34px;
}
.line-height-44 {
  line-height: 44px;
}
.line-height37 {
  line-height: 37px;
}
.line-height-73 {
  line-height: 73px;
}
.line-height-63 {
  line-height: 63px;
}
.line-height60 {
  line-height: 60px;
}
.line-height76 {
  line-height: 76px;
}

/* transform to parallelogram */
.tranform-skew20 {
  transform: skew(-20deg);
}

/* remove transformation */
.remove-transform {
  transform: skew(20deg);
}
.remove-transform10 {
  transform: skew(10deg);
}
.remove-transform15 {
  transform: skew(15deg);
}

/* image covers its parent space */
.image-cover-full {
  height: 100%;
  width: 100%;
}
/* display */
.d-flex {
  display: flex;
}
.d-grid {
  display: grid;
}
/* justify */
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}

/* widths */
.width-48 {
  width: 48%;
}
.width-96 {
  width: 96%;
}
.width-100 {
  width: 100%;
}

/* cursors */
.cursor-pointer {
  cursor: pointer;
}

/* align-content */
.aligncontent-baseline {
  align-content: baseline;
}
.alignitems-center {
  align-items: center;
}
.alignself-baseline {
  align-self: baseline;
}

/* background blur */
.background-blur {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(11.6334px);
}

/* mobile and desktop specific things */
.mobileonly {
  display: none;
}
.desktoponly {
  display: block;
}
/* Overflow */
.overflow-hidden {
  overflow: hidden;
}

/* primary border */
.container-border {
  border: 1px solid transparent;
  border-image: linear-gradient(
    180deg,
    #fff017 0%,
    rgba(255, 240, 23, 0) 140.54%
  );
  border-image-slice: 1;
}
.dropdown-popup-style {
  padding: 1px;
  border-radius: 0;
  border: 1px solid #fff017;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 23.2667px 23.2667px 46.5335px rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: blur(11.6334px);
  backdrop-filter: blur(11.6334px);
  .ant-select-item {
    color: #fff;
  }
}
.rc-virtual-list-scrollbar-thumb {
  background: #fff017 !important;
}
.rc-virtual-list-scrollbar {
  width: 5px !important;
}

.error-message {
  color: red;
  font-size: 12px;
  font-family: "Cairo Medium";
  display: flex;
  align-items: flex-start;
  line-height: 12px;
}
.spinner-loading {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  .loader {
    .ant-spin-dot-item {
      background-color: #fff017;
    }
  }
}
.auth-loading {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  .loader {
    .ant-spin-dot-item {
      background-color: #000;
    }
  }
}
.cookies-preferences-button {
  color: #000;
  background-color: #d9d9d9;
  font-size: 14px;
  border: none;
  cursor: pointer;
  padding: 0 10px;
  font-family: "Cairo Medium";
  height: 32px;
}
.cookie-consent-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Cairo Medium";
}
.signup-correct {
  svg {
    color: rgb(66, 197, 66);
  }
}
.signup-wrong {
  svg {
    color: rgb(231, 65, 65);
  }
}
.password-conditions {
  font-size: 12px;
  line-height: 15px;
  height: 70px;
  color: #fff;
}
@media (min-width: 320px) and (max-width: 480px) {
  .mobileonly {
    display: block;
  }
  .desktoponly {
    display: none;
  }
  .font72 {
    font-size: 30px;
  }
  .line-height76 {
    line-height: 33px;
  }
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
